import React, { useState, useEffect } from 'react';

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function RetailSyncTableBigC({ id, sku, title, bigc_error, svcode, bigcode, cacode, created_at, quantity }) {
    // const moment = require('moment')
    // const timeSync = moment.utc(sync_stamp).utcOffset(-720).format('hh:mm A - YYYY-MM-DD')
    return (
        <>
            <tr id={id}>
                <td>{id}</td>
                <td>{sku}</td>
                <td>{title}</td>
                <td className="text-center">{quantity}</td>
                <td>{bigc_error}</td>
                <td className="text-center">{svcode}</td>
                <td className="text-center">{bigcode}</td>
                <td className="text-center">{cacode}</td>
                <td>{created_at}</td>
            </tr>
        </>
    );
}

export default RetailSyncTableBigC;