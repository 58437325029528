import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from "react-select";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import categories from './Static/category.json';
import brands from './Static/brand.json';
import SweetAlert from "react-bootstrap-sweetalert";

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";


function ItemsList(props) {
    const { itemID,
        systemSKU,
        createdBy,
        itemIdList,
        titleList,
        priceList,
        paymentMethodList,
        percentageRateList,
        itemStatusList, itemNotesList,
        categoryMatchList, brandMatchList,
        notify, tableRealod, defaultTableUpdated, fnReqSearchQueryState, search,
        firstName, lastName, email, phone, address, city, state, zip, } = props

    
    const [productTitle, setProductTitle] = useState("");
    const [price, setPrice] = useState("");

    const [displayPrice, setdisplayPrice] = useState("");

    const [paymentMethod, setPaymentMethod] = useState("");
    const [payoutPercentage, setPayoutPercentage] = useState("");

    const [category, setCategory] = useState("");
    const [catLabel, setCatLabel] = useState("");

    const [brand, setBrand] = useState("");
    const [brandLabel, setBrandLabel] = useState("");

    const [itemStatus, setItemStatus] = useState("");
    const [productNotes, setProductNotes] = useState("");



    // setup price for display only
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const priceDisplay = formatter.format(priceList);

    useEffect(() => {
        setTimeout(() => {
            setdisplayPrice(priceDisplay)
            setProductTitle(titleList)
            setPrice(priceList)
            setPaymentMethod(paymentMethodList)
            setPayoutPercentage(percentageRateList)
            setCategory(categoryMatchList)
            setBrand(brandMatchList)
            setItemStatus(itemStatusList)
            setProductNotes(itemNotesList)
        }, 100)
        // setTimeout()
    }, [state]);


    //refactor these two for in's to higer order too late at night to think
    const getCatVal = () => {
        for (var key in categories) {
            if (categories[key].value === categoryMatchList) {
                return categories[key].label
            }
        };
    };

    const getBrandVal = () => {
        for (var key in brands) {
            if (brands[key].value === brandMatchList) {
                return brands[key].label
            }
        };
    };

    // Validator for price change
    const isRequired = (value) => value !== null && value !== "" && value;
    const isNumber = (value) => !isNaN(value) && value !== "";
    const [priceRequiredSubState, setPriceRequiredState] = useState(true);

    //edit item handlers
    // const handlePrice = e => setPrice(+e.target.value);
    const handleProductTitle = e => setProductTitle(e.target.value);
    const handleProductNotes = e => setProductNotes(e.target.value);
    const handlePrice = (e) => {
        setPrice(e.target.value)
        isNumber(e.target.value) ? setPriceRequiredState(true) : setPriceRequiredState(false);
    }

    const handleItemUpdate = e => {
        props.showLoader()
        if (!priceRequiredSubState) {
            setPriceRequiredState(false);
            props.hideLoader()
            notify("tc", "danger", "Price must be a number!")
            return
        } else {
            setPriceRequiredState(true);
        }
        axios.put('/cnor/items', {
            e, productTitle, price, paymentMethod, payoutPercentage, itemStatus, productNotes,
            firstName, lastName, email, phone, address, city, state, zip, itemID, category, brand, catLabel, brandLabel
        })
            .then((response) => {
                props.hideLoader()
                notify("tc", "success", "Item Updated!")
                if (fnReqSearchQueryState === true) {
                    defaultTableUpdated();
                } else {
                    search();
                }
                setPriceRequiredState(true)
            }).catch(err => {
                props.hideLoader()
                console.log(err)
                notify("tc", "danger", err.response.request.response)
            })
    }

    //Delete setup
    const [alert, setAlert] = useState(null);
    const handleSubmitDelteItem = e => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => successDelete(e)}
                onCancel={() => cancelDetele()}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="info"
                confirmBtnText="Yes, delete item!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover product data
            </SweetAlert>
        );
    };
    //Delete Item 
    const successDelete = e => {
        props.showLoader()
        axios.delete(`/cnor/items/${e}/${itemID}`)
            .then(() => {
                props.hideLoader()
                notify("tc", "danger", "Item Deleted!")
                tableRealod()
                hideAlert()
                // rerender page based on default or search
                if (fnReqSearchQueryState === true) {
                    defaultTableUpdated();
                } else {
                    search();
                }
            }).catch(err => {
                props.hideLoader()
                console.log(err)
                notify("tc", "danger", err.response.request.response)
            })
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                No problem, we all press buttons by mistake!
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };





    const style = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: "none"
        })
    };

    function handlePrint() {
        const pTitle = productTitle.replace(/[!@?#$()%^&*]/g, "");
        const customSKU = 'L9CS' + itemID;
        const url = `/print/${customSKU}/${price}/${pTitle}/${createdBy}`;
        window.open(url);
    }

    return (
        <>
            {alert}
            <tbody key={itemID}>
                <tr>
                    <td className="text-left" width="180px">
                        <Form.Control
                            className="border border-white"
                            defaultValue={titleList}
                            onChange={handleProductTitle}
                            type="text"
                        ></Form.Control>
                    </td>
                    <td width="50px"
                        className={
                            priceRequiredSubState ? "has-success text-center" : "has-error text-center"
                        }
                    >
                        <Form.Control
                            className="border border-white"
                            placeholder={priceDisplay}
                            onChange={handlePrice}
                            type="text"
                        >
                        </Form.Control>
                    </td>
                    <td className="text-center" width="110px">
                        <Form.Group>
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={style}
                                className="mt-1 primary"
                                classNamePrefix="react-select"
                                name="Category"
                                // onChange={(value) => setCategory(value.value)}
                                onChange={(value) => {
                                    setCategory(value.value)
                                    setCatLabel(value.label)
                                }}
                                options={categories}
                                placeholder={getCatVal()}
                            />
                        </Form.Group>
                    </td>
                    <td width="90px">
                        <Form.Group style={{ width: '90px' }}>
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={style}
                                className="mt-1 primary"
                                classNamePrefix="react-select"
                                name="Brand"
                                onChange={(value) => {
                                    setBrand(value.value)
                                    setBrandLabel(value.label)
                                }}
                                options={brands}
                                placeholder={getBrandVal()}
                            />
                        </Form.Group>
                    </td>
                    <td width="80px">
                        <Form.Group>
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={style}
                                className="mt-1 primary"
                                classNamePrefix="react-select"
                                // name="Payment Option"
                                onChange={(value) => setPaymentMethod(value.value)}
                                options={[
                                    { value: "in-store", label: "In-Store Credit" },
                                    { value: "paypal", label: "PayPal" },
                                    { value: "Venmo", label: "Venmo" },
                                ]}
                                placeholder={paymentMethodList}
                            />
                        </Form.Group>
                    </td>
                    <td width="80px">
                        <Form.Group>
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={style}
                                className="mt-1 primary"
                                classNamePrefix="react-select"
                                name="Payout Method"
                                // value={payoutPercentage}
                                onChange={(value) => setPayoutPercentage(value.value)}
                                options={[
                                    { value: 65, label: "65" },
                                    { value: 75, label: "75" },
                                    { value: 90, label: "90" },
                                ]}
                                placeholder={percentageRateList}
                            />
                        </Form.Group>
                    </td>
                    <td width="90px">
                        <Form.Group>
                            <Select
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                styles={style}
                                className="mt-1 primary"
                                classNamePrefix="react-select"
                                name="Item Status"
                                // value={itemStatus}
                                onChange={(value) => setItemStatus(value.value)}
                                options={[
                                    { value: "Ready For Sale", label: "Ready For Sale" },
                                    { value: "Paid To Customer", label: "Paid To Customer" },
                                    { value: "Voided", label: "Voided" },
                                    { value: "Customer To Pick Up Product", label: "Customer To Pick Up Product" },
                                    { value: "Product was Picked Up", label: "Product was Picked Up" },
                                    { value: "Donated", label: "Donated" }
                                ]}
                                placeholder={itemStatusList}
                            />
                        </Form.Group>
                    </td>
                    <td width="220px" className="text-center">
                        <Form.Control
                            className="border border-white"
                            defaultValue={itemNotesList}
                            onChange={handleProductNotes}
                            type="text"
                        ></Form.Control>
                    </td>
                    <td width="40px" className="td-actions text-center">
                        <OverlayTrigger
                            // onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-1">Print Label</Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs"
                                variant="warning"
                                target="_blank"
                                onClick={handlePrint}
                            >
                                <i className="fa fa-print"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-1">Save Changes</Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs"
                                variant="info"
                                onClick={() => {
                                    handleItemUpdate(itemIdList);
                                }}
                            >
                                <i className="far fa-save"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-1">Are you sure you want to Delete?</Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs"
                                variant="danger"
                                onClick={() => {
                                    handleSubmitDelteItem(itemIdList);
                                }}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            </tbody>
        </>
    );
}
const mapDispatchToProps = {
    showLoader,
    hideLoader
}

export default connect(undefined, mapDispatchToProps)(ItemsList);