import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PackageEditor from './PackageEditor';
import Photos from './Photos';
const Packages = () => {
    const [activeTab, setActiveTab] = useState(
        localStorage.getItem('activeTab') || 'packages'
    );

  return (
    <Container>
        <div className="form-check">
        <input className="" type="radio" name="package" value="single" onClick={(e) => setActiveTab(e.target.value)}/>
        <label className=''>Single Package</label>
        </div>
        <div className="form-check">
        <input type="radio" name="package" value="file" onClick={(e) => setActiveTab(e.target.value)}/>
        <label>Package File</label>
        </div>
        

        {/* if single package is selected, show the package editor page */}
        {activeTab === 'single' && <PackageEditor />}
        {/* if package file is selected, show the package file page */}
        {activeTab === 'file' && <Photos page = {'Packages'}/>}

    </Container>


  )
}

export default Packages