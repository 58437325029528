// initial state
const initialState = {
    username: '',
    loading: false,
};

// action types
const UPDATE_USERNAME = 'UPDATE_USERNAME';
const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

export const showLoader = () => {
    return {
        type: SHOW_LOADER,
        // payload: recipe
    }
}

export const hideLoader = () => {
    return {
        type: HIDE_LOADER,
        // payload: recipe
    }
}

export const updateUsername = (username) => ({ type: UPDATE_USERNAME, payload: username })

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER: {
            return {
                ...state,
                loading: true
            }
        }
        case HIDE_LOADER: {
            return {
                ...state,
                loading: false
            }
        }        
    
        case UPDATE_USERNAME: {
            return {
                ...state,
                username: action.payload
            }
        }

        default: return state;
    }
}
export default reducer;