import React from "react";
import axios from 'axios';
import WebReturn from "../Components/WebReturn";

import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";

function WebReturns() {

// Google Sheets API Call
  const handleSubmit = e => {
    e.preventDefault();
    console.log('I ran')
    axios.get('/api/web')
      .then((response) => {
        console.log(response)
      }).catch((err) => {
        console.log(err.response)
      })
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Return BigCommerce Orders</Card.Title>
              </Card.Header>
              <Card.Body>
                <WebReturn />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WebReturns;
