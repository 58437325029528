import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Switch, Route } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { updateUsername, showLoader } from '../store/reducer';

import PrivateSidebar from "./Components/Sidebar/PrivateSidebar.js";
import PrivateNavbar from "./Components/Navbars/PrivateNavbar.js";
import PrivateFooter from "./Components/Footers/PrivateFooter.js";
import routes from "../routes.js";
// import sidebarImage from "../assets/img/bikebg.jpg";
import Loading from '../views/Components/Static/Loading/Loader';

function Private(props) {
  // const dispatch = useDispatch();
  const [isAdmin, setisAdmin] = useState(false);

  useEffect(() => {
    axios.get('/auth/user')
      .then((response) => {
        setisAdmin(response.data.isAdmin)
        return props.updateUsername(response.data.username)
      }).catch(err => {
        props.history.push('/public/login');
      })
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/private") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      }
      if (prop.layout === "/private") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      }
      else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper">
        <PrivateSidebar
          routes={routes}
          // image={sidebarImage}
          isAdmin={isAdmin}
        />
        <div className="main-panel">
          <PrivateNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <PrivateFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
      <Loading />
    </>
  );
}

const mapDispatchToProps = {
  showLoader,
  updateUsername,
}

export default connect(undefined, mapDispatchToProps)(Private);
