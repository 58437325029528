import React, { useState, useEffect } from 'react';
var Barcode = require('react-barcode');
import './Print.css'

import {
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
} from "react-bootstrap";

function Print(props) {
    useEffect(() => {
        setPrintIDParam(props.match.params.id)
        setPrintPrice(props.match.params.price)
        setPrintTitle(props.match.params.title)
        setprintCreatedBy(props.match.params.createdBy)
    }, []);

    const [printIDParam, setPrintIDParam] = useState('');
    const [printPrice, setPrintPrice] = useState('');
    const [printTitle, setPrintTitle] = useState('');
    const [printCreatedBy, setprintCreatedBy] = useState('');

    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        currency: 'USD',
    });

    let formatted = formatter.format(printPrice);
    let formatStyle = formatted.toString().split('.')
    let price = formatStyle[0]
    let cents = formatStyle[1]

    return (
        <>
            <Media>
                <Media.Body>
                    <div className="print-container">
                        <div>
                            <div className="print-location">
                                <div className="print-location-left">{printCreatedBy} - Consignment - No Return</div>
                                <div className="print-location-right">Consignment - No Return</div>
                            </div>
                            <div className="title-container">
                                <div className="label-left">
                                    <div className="label-left-inner">
                                        <div className="price-container">
                                            <span className="currency-symbol">$</span>
                                            <span className="price">{price}</span>
                                            <span className="cents">.{cents}</span>
                                        </div>
                                        <div className="title">
                                            <span>{printTitle}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="label-right">
                                    <Barcode
                                        value={printIDParam}
                                        format={'CODE128'}
                                        height={42}
                                        fontSize={14}
                                        textMargin={2}
                                        width={2}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </Media.Body>
            </Media>
        </>
    );
}
// window.onload = function() { window.print(), setTimeout(window.close, 0);}
export default Print;
// save this function for a bit until verified package checkdigit works correctly
// function checkDigit(val) {
//     let array = val.split('').reverse();

//     let total = 0;
//     let i = 1;
//     array.forEach(number => {
//         number = parseInt(number);
//         if (i % 2 === 0) {
//             total = total + number;
//         }
//         else {
//             total = total + (number * 3);
//         }
//         i++;
//     });

//     return (Math.ceil(total / 10) * 10) - total;
// }
// let digit = checkDigit(printIDParam)
// let UPC = printIDParam + digit