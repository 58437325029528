import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import ItemsList from './ItemsList';
import Select from "react-select";

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function ItemCard({ itemID, firstName, lastName, dbID }) {
    // item = parseInt(itemID)
    const notificationAlertRef = React.useRef(null);
    const notify = (place, type, msg) => {
        var type = type
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    

    const tableRealod = () => {
        console.log('tableRealod funtion not needed')
    }
    const defaultTableUpdated = () => {
        console.log('defaultTableUpdated funtion not needed')
    }
    const fnReqSearchQueryState = () => {
        console.log('fnReqSearchQueryState funtion not needed')
    }
    const search = () => {
        console.log('search funtion not needed')
    }


    let [item, setItem] = useState('');
    useEffect( () => {
         axios.get(`/cnor/item/${itemID}`)
            .then((response) => {
                setItem(response.data)
            }).catch(err => {
                console.log((err))
            })
    }, []);

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="table-with-links">
                            <Card.Header>
                                <Card.Header>
                                    <Card.Title as="h4"> <span className="text-success font-weight-bold">{firstName} {lastName}'s</span> - Consignment Items:</Card.Title>
                                </Card.Header>
                            </Card.Header>
                            <Card.Body className="table-full-width">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="text-left">Title</th>
                                            <th className="text-left">Price</th>
                                            <th className="text-left">Category</th>
                                            <th className="text-left">Brand</th>
                                            <th className="text-left">Payment</th>
                                            <th className="text-left">Payout %</th>
                                            <th className="text-left">Item Status</th>
                                            <th className="text-left">Notes</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>

                                    <ItemsList
                                        itemID={item.item_id}
                                        systemSKU={item.system_sku}
                                        createdBy={item.created_by}
                                        itemIdList={dbID}
                                        titleList={item.title}
                                        priceList={item.price}
                                        paymentMethodList={item.payment_method}
                                        percentageRateList={item.percentage}
                                        itemStatusList={item.item_status}
                                        itemNotesList={item.p_notes}
                                        categoryMatchList={item.category}
                                        brandMatchList={item.brand}

                                        firstName={item.first_name}
                                        lastName={item.last_name}
                                        email={item.email}
                                        phone={item.phone}
                                        address={item.address}
                                        city={item.city}
                                        state={item.state}
                                        zip={item.zip}

                                        notify={notify}
                                        tableRealod={tableRealod}
                                        defaultTableUpdated={defaultTableUpdated}
                                        fnReqSearchQueryState={fnReqSearchQueryState}
                                        search={search}
                                    />
                                </Table>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ItemCard;