import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import ItemsList from './ItemsList';
import Select from "react-select";

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function Items({ cnor, fnReqSearchQueryState, defaultTableUpdated, search,
                firstName, lastName, email, phone, address, city, state, zip }) {

    const notificationAlertRef = React.useRef(null);
    const notify = (place, type, msg) => {
        var type = type
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };


    let [items, setItems] = useState([]);
    useEffect(() => {
        axios.get(`/cnor/items/${cnor}`)
            .then((response) => {
                setItems(response.data)
            }).catch(err => {
                console.log((err))
            })
    }, []);

    let tableRealod = () => {
        axios.get(`/cnor/items/${cnor}`)
            .then((response) => {
                setItems([])
                setItems(response.data)
            }).catch(err => {
                console.log((err.response.data))
            })
    };

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="table-with-links">
                            <Card.Header>
                                <Card.Header>
                                    <Card.Title as="h4"> <span className="text-success font-weight-bold">{firstName} {lastName}'s</span> - Consignment Items:</Card.Title>
                                </Card.Header>
                            </Card.Header>
                            <Card.Body className="table-full-width">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="text-left">Title</th>
                                            <th className="text-left">Price</th>
                                            <th className="text-left">Category</th>
                                            <th className="text-left">Brand</th>
                                            <th className="text-left">Payment</th>
                                            <th className="text-left">Payout %</th>
                                            <th className="text-left">Item Status</th>
                                            <th className="text-left">Info</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>

                                    {items.map( (item, i) => 
                                        <ItemsList
                                        key={i}
                                        itemID={item.item_id}
                                        systemSKU={item.system_sku}
                                        createdBy={item.created_by}
                                        itemIdList={item.id}
                                        titleList={item.title}
                                        priceList={item.price}
                                        paymentMethodList={item.payment_method}
                                        percentageRateList={item.percentage}
                                        itemStatusList={item.item_status}
                                        itemNotesList={item.p_notes}
                                        categoryMatchList={item.category}
                                        brandMatchList={item.brand}

                                        firstName={firstName}
                                        lastName={lastName}
                                        email={email}
                                        phone={phone}
                                        address={address}
                                        city={city}
                                        state={state}
                                        zip={zip}                                  

                                        notify={notify}
                                        tableRealod={tableRealod}
                                        defaultTableUpdated={defaultTableUpdated}
                                        fnReqSearchQueryState={fnReqSearchQueryState}
                                        search={search}
                                        />
                                    )}
        
                                </Table>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Items;