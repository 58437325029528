import React from "react";
import ConsignPolicies from "../Components/Static/ConsignPolicies"
import QuickLinks from "../Components/Static/QuickLinks";
 
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Dashboard() {
  return (
    <>
      <Container fluid>
        {/* <ConsignPolicies /> */}
        <QuickLinks />
        <div className="d-none d-sm-block">
        <Container>
            <Row>
                <Col md="6" sm="12">
                  <link href="//www.snow-forecast.com/stylesheets/feed.css" media="screen" rel="stylesheet" type="text/css" /><iframe  allowtransparency="true" height="272" width="469" src="//www.snow-forecast.com/resorts/Alta/forecasts/feed/top/m" scrolling="no" frameBorder="0" marginWidth="0" marginHeight="0"><p>Your browser does not support iframes.</p></iframe>
                </Col>
                <Col md="6" sm="12">          
                  <link href="//www.snow-forecast.com/stylesheets/feed.css" media="screen" rel="stylesheet" type="text/css" /><iframe allowtransparency="true" height="272" width="469" src="//www.snow-forecast.com/resorts/Snowbird/forecasts/feed/top/m" scrolling="no" frameBorder="0" marginWidth="0" marginHeight="0"><p>Your browser does not support iframes.</p></iframe>
                </Col>                            
            </Row>
            <Row>
                <Col md="6" sm="12">
                  <link href="//www.snow-forecast.com/stylesheets/feed.css" media="screen" rel="stylesheet" type="text/css" /><iframe  allowtransparency="true" height="272" width="469" src="//www.snow-forecast.com/resorts/Park-City/forecasts/feed/top/m" scrolling="no" frameBorder="0" marginWidth="0" marginHeight="0"><p>Your browser does not support iframes.</p></iframe>
                </Col>
                <Col md="6" sm="12">          
                  <link href="//www.snow-forecast.com/stylesheets/feed.css" media="screen" rel="stylesheet" type="text/css" /><iframe allowtransparency="true" height="272" width="469" src="//www.snow-forecast.com/resorts/Brian-Head-Resort/forecasts/feed/top/m" scrolling="no" frameBorder="0" marginWidth="0" marginHeight="0"><p>Your browser does not support iframes.</p></iframe>
                </Col>                            
            </Row>            
        </Container>
        </div>
      </Container>
    </>
  );
}

export default Dashboard;
