import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';


import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function PrivateSidebar({ routes, image, background, isAdmin }) {

  const username = useSelector((reduxState) => reduxState.username);
  const createLinks = (routes) => {
    return routes.map((prop, key) => {

      if (routes[key].adminMenu === true && isAdmin === true) {
        return (
          <Nav.Item
            className={activeRoute(prop.layout + prop.path)}
            key={key}
            as="li"
          >
            <Nav.Link to={prop.layout + prop.path} as={Link}>
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </Nav.Link>
          </Nav.Item>
        );
      }

      if (routes[key].adminMenu === false) {
        return (
          <Nav.Item
            className={activeRoute(prop.layout + prop.path)}
            key={key}
            as="li"
          >
            <Nav.Link to={prop.layout + prop.path} as={Link}>
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </Nav.Link>
          </Nav.Item>
        );
      }
    });
  };

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            <a
              className="simple-text logo-mini"
              href="/private/dashboard"
            >
              <div className="logo-img">
                <img
                  src={require("../../../assets/img/L9-Logo.png").default}
                  alt="react-logo"
                />
              </div>
            </a>
            <a
              className="text-capitalize simple-text logo-normal pt-2"
              href="/private/dashboard"
            >
              Business Apps

            </a>
          </div>
          <div className="user pa-0">
            <div className="photo pt-1 mr-3">
            {username == "Millcreek" || username == "Ogden" || username == "Downtown" || username == "Orem"  ? (
                  <i className="fa fa-store"></i>
              ) : (
                  <i className="fa fa-user"></i>
              )}
            </div>
            <div className="logo pt-1">
              <div>
                <div className="info">
                  <a data-toggle="collapse" aria-expanded="true">
                    <span className="simple-text text-capitalize text-light pt-2">{username}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url('" + image + "')",
          }}
        ></div>
      </div>
    </>
  );
}

let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  // component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

PrivateSidebar.defaultProps = {
  // image: "",
  background: "azure",
  // routes: [],
};

PrivateSidebar.propTypes = {
  image: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string,
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string,
        }),
      }),
    ])
  ),
};

export default PrivateSidebar;
