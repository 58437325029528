import React from "react";

import {
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
} from "react-bootstrap";

function RegisterCTA() {
    return (
        <>
            <Media>
                <Media.Body>
                    <div className="pb-0">
                        <h3><b>We Use Venmo to pay you promptly!</b></h3>
                    </div>
                    <img className={"venmo-logo"} src={require("../../../assets/img/venmo-logo.png").default} alt="venmo-logo" />
                    <p>
                        <span className="font-weight-bold">We pay exclusively with Venmo, please make sure you enter your phone and email address correctly on this form, or you will not be paid promptly!
                            <br />
                            <br />
                            You will receive a text message and email verification when you create your account.
                        </span>
                    </p>
                </Media.Body>
            </Media>
            {/* <Media>
                <div className="media-left">
                    <div className="icon">
                        <i className="fas fa-house-user mr-3"></i>
                    </div>
                </div> 
                <Media.Body>
                    <h4>Consignor Club</h4>
                    <p>
                        We are proud to announce the launch of the L9 Consignor Club! The Club is a tiered membership program where you can earn exclusive benefits depending on how much gear you consign with us. The tiers are determined by either number of items sold or dollar amount sold.
                        Club Perks:<br />
                        - Access to Exclusive Members Only Online Discounts<br />
                        - Invites to Our Exclusive Members Only Parties<br />
                        - Tiered Discounts on Ski & Bike Shop Services & Rentals
                    </p>
                </Media.Body>
            </Media>
            <Media>
                <div className="media-left">
                    <div className="icon">
                        <i className="nc-icon nc-planet"></i>
                    </div>
                </div>
                <Media.Body>
                    <h4>Future CTA Here</h4>
                    <p>
                        Future CTA here
                    </p>
                </Media.Body>
            </Media> */}
        </>
    );
}

export default RegisterCTA;