import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import 'react-phone-number-input/style.css'
import NotificationAlert from "react-notification-alert";
import PhoneInput from 'react-phone-number-input'

import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
} from "react-bootstrap";

function ConsignorSignup(props) {
    //Sweet alert for success
    const [alert, setAlert] = useState(null);
    const successAlert = (message) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Nice work!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                Congrats Your Consignor Account <span className="text-success font-weight-bold">{message}</span> Has Been Created!
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
        window.location.reload(false);
    };
    const notificationAlertRef = React.useRef(null);
    const notify = (place, type, msg) => {
        var type = type
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 10,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    //Axios error response
    const [errorResponse, setErrorResponse] = useState('');
    const [errorState, setErrorState] = useState(true);

    // route forward might not need this
    const history = useHistory();

    const routeChange = () => {
        let path = `/auth/register-page-item`;
        history.push(path);
    }

    // Validators
    const isRequired = (value) => value !== null && value !== "" && value;

    const emailValidation = (value) =>
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value
        );
    const isNumber = (value) => !isNaN(value) && value !== "";

    //First name
    const [firstname, setFirstname] = useState('');
    const [fnRequiredState, setFnRequiredState] = useState(true);

    //Last name
    const [lastname, setLastname] = useState('');
    const [lnRequiredState, setLnRequiredState] = useState(true);

    //email 
    const [email, setEmail] = useState('');
    const [emailRequiredState, setEmailrequiredState] = useState(true);

    //Address
    const [address, setAddress] = useState('');
    const [addressRequiredState, setAddressRequiredState] = useState(true);

    //City
    const [city, setCity] = useState('');
    const [cityRequiredState, setCityRequiredState] = useState(true);

    //State
    const [state, setState] = useState('');
    const [stateRequiredState, setStateRequiredState] = useState(true);

    //Zip
    const [zip, setZip] = useState('');
    const [zipRequiredState, setZipRequiredState] = useState(true);

    //Phone
    const [phone, setPhone] = useState('');
    const [phoneRequiredState, setPhoneRequiredState] = useState(true);

    //Code
    const [code, setCode] = useState('');
    const [codeRequiredState, setCodeRequiredState] = useState(true);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    // handlers 
    const resetResponseError = () => (setErrorState(true))

    const handleFirstName = (e) => {
        setFirstname(e.target.value);
        isRequired(e.target.value) ? setFnRequiredState(true) : setFnRequiredState(false);
    }

    const handleLastName = (e) => {
        setLastname(e.target.value);
        isRequired(e.target.value) ? setLnRequiredState(true) : setLnRequiredState(false);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
        emailValidation(e.target.value) ? setEmailrequiredState(true) : setEmailrequiredState(false);
    }

    const handleAddress = (e) => {
        setAddress(e.target.value);
        isRequired(e.target.value) ? setAddressRequiredState(true) : setAddressRequiredState(false);
    }

    const handleCity = (e) => {
        setCity(e.target.value);
        isRequired(e.target.value) ? setCityRequiredState(true) : setCityRequiredState(false);
    }

    const handleState = (e) => {
        setState(e.target.value);
        isRequired(e.target.value) ? setStateRequiredState(true) : setStateRequiredState(false);
    }

    const handleZip = (e) => {
        setZip(e.target.value);
        isNumber(e.target.value) ? setZipRequiredState(true) : setZipRequiredState(false);
    }

    const handlePhone = (phone) => {
        setPhone(phone);
        // isRequired(!phone) ? setPhoneRequiredState(false) : setPhoneRequiredState(true);
    }


    const [agreeErrorState, setAgreeErrorState] = useState(true);

    const [agree, setAgree] = useState('');
    const [agreeRequiredState, setAgreeRequiredState] = useState(true);

    const [codeShow, setCodeShow] = useState(true);


    const handleAgree = (e) => {
        if (e.target.checked === true) {
            setAgree('yes')
            setAgreeRequiredState(true)
        } else if (e.target.checked === false) {
            setAgreeRequiredState(false)
            setAgree('no')
        }
    }



    const formChecker = (e) => {
        e.preventDefault();
        let formNotFilled = 0
        console.log(formNotFilled, '3')
        if (!fnRequiredState || !isRequired(firstname)) {
            setFnRequiredState(false);
            formNotFilled++
        }
        if (!lnRequiredState || !isRequired(lastname)) {
            setLnRequiredState(false);
            formNotFilled++
        }
        if (!emailRequiredState || !emailValidation(email)) {
            setEmailrequiredState(false);
            formNotFilled++
        }
        if (!addressRequiredState || !isRequired(address)) {
            setAddressRequiredState(false);
            formNotFilled++
        }
        if (!cityRequiredState || !isRequired(city)) {
            setCityRequiredState(false);
            formNotFilled++
        }
        if (!stateRequiredState || !isRequired(state)) {
            setStateRequiredState(false);
            formNotFilled++
        }
        if (!zipRequiredState || !isRequired(zip)) {
            setZipRequiredState(false);
            formNotFilled++
        }
        if (!phoneRequiredState || !isRequired(phone)) {
            setPhoneRequiredState(false);
            formNotFilled++
        }
        if (!agreeRequiredState || agree) {
            setAgreeRequiredState(false)
            setAgree('')
            formNotFilled++
        }

        return formNotFilled
    }

    const handleSubmitCode = e => {
        e.preventDefault();
        const formNotFilled = formChecker(e)

        if (formNotFilled > 0) {
            setAgreeRequiredState(true)
            console.log(agreeRequiredState, 'TheFF-TRUE')
            axios.post('/cnor/sms-check', { firstname, phone })
                .then((response) => {
                    console.log('res-sms', response.status)
                    if (response.status == 200) {
                        notify("tc", "success", "Please check your phone for the signup code")
                        setCodeShow(false)
                    }
                    else if (response.status == 416) {
                        notify("tc", "danger", "Something went wrong with your number")
                    } else {
                        notify("tc", "danger", response.status)
                        setAgreeRequiredState(true)
                        setAgree('');
                    }
                }).catch((err) => {
                    setErrorState(false)
                    setErrorResponse(err.response)
                })
            // return
        } else {
            setAgreeRequiredState(false)
            setAgree('no');
            if (agreeRequiredState) {
                console.log(agreeRequiredState, 'Need to agree')
            }

        }
    }

    const handleCodeCheck = e => {
        e.preventDefault();
        if (e.target.value === 'L924' || e.target.value === 'l924') {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        const formNotFilled = formChecker(e)
        console.log('formNotFilled', formNotFilled)
        if (formNotFilled > 0) {
            setButtonDisabled(true)
            return
        }
        else {
            setFnRequiredState(true);
            setLnRequiredState(true);
            setEmailrequiredState(true);
            setAddressRequiredState(true);
            setCityRequiredState(true);
            setStateRequiredState(true);
            setZipRequiredState(true);
            setPhoneRequiredState(true);
            // setAgreeRequiredState(true);
        }
        props.showLoader()
        console.log(firstname, lastname, email, address, city, state, zip, phone)
        axios.post('/cnor/create', { firstname, lastname, email, address, city, state, zip, phone })
            .then((response) => {
                props.hideLoader()
                successAlert(response.data.email)
                setFirstname('')
                setLastname('')
                setEmail('')
                setAddress('')
                setCity('')
                setState('')
                setZip('')
                setPhone('')
            }).catch((err) => {
                props.hideLoader()
                setErrorState(false)
                setErrorResponse(err.response.data)
            })
    }

    let path = window.location.pathname

    return (
        <>
            {alert}
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Form action="#" method="#">
                <Card className="card-plain">
                    <div >
                        <Row>
                            <Col>
                                <Form.Group
                                    className={
                                        fnRequiredState ? "has-success  mr-0  pr-0" : "has-error mr-0  pr-0"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* First Name"
                                        type="text"
                                        value={firstname}
                                        onChange={handleFirstName}
                                    ></Form.Control>
                                    {fnRequiredState ? null : (
                                        <label className="error">
                                            First Name is required.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col className='mr-0 ml-0 pl-0 mr-0'>
                                <Form.Group
                                    className={lnRequiredState ? "has-success ml-0  pl-0" : "has-error ml-0  pl-0"}
                                >
                                    <Form.Control
                                        placeholder="* Last Name"
                                        type="text"
                                        value={lastname}
                                        onChange={handleLastName}
                                    ></Form.Control>
                                    {lnRequiredState ? null : (
                                        <label className="error">
                                            Last Name is required.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-0 pb-0" lg="6">
                                <Form.Group
                                    className={
                                        addressRequiredState ? "has-success" : "has-error"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* Home Address"
                                        type="text"
                                        value={address}
                                        onChange={handleAddress}
                                    ></Form.Control>
                                    {addressRequiredState ? null : (
                                        <label className="error">
                                            Address is required.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col className="pt-0 pb-0" lg="6">
                                <Form.Group
                                    className={
                                        emailRequiredState ? "has-success" : "has-error"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* Email Address"
                                        type="email"
                                        value={email}
                                        onChange={handleEmail}
                                    ></Form.Control>
                                    {emailRequiredState ? null : (
                                        <label className="error">
                                            This field is required to be an email.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-0 pb-0" lg="4">
                                <Form.Group
                                    className={
                                        cityRequiredState ? "has-success" : "has-error"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* City"
                                        type="text"
                                        value={city}
                                        onChange={handleCity}
                                    ></Form.Control>
                                    {cityRequiredState ? null : (
                                        <label className="error">
                                            City is required.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col className="pt-0 pb-0" lg="4">
                                <Form.Group
                                    className={
                                        stateRequiredState ? "has-success" : "has-error"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* State"
                                        type="text"
                                        value={state}
                                        onChange={handleState}
                                    ></Form.Control>
                                    {stateRequiredState ? null : (
                                        <label className="error">
                                            State is required.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col className="pt-0 pb-0" lg="4">
                                <Form.Group
                                    className={
                                        zipRequiredState ? "has-success" : "has-error"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* Zipcode"
                                        type="text"
                                        value={zip}
                                        onChange={handleZip}
                                    ></Form.Control>
                                    {zipRequiredState ? null : (
                                        <label className="error">
                                            Zipcode is required and must be a number.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center' style={{ paddingTop: 0 + 'px', mpaddingBottom: 0 + 'px' }}>
                            <Col className="d-flex justify-content-center pt-0 pb-0" lg="8" style={{ paddingTop: 0 + 'px', mpaddingBottom: 0 + 'px' }}>
                                <Form.Group
                                    className={
                                        phoneRequiredState ? "has-success center" : "has-error"
                                    }
                                >
                                    <div className="form-control" >
                                        <PhoneInput
                                            international={false}
                                            defaultCountry="US"
                                            type="phone"
                                            placeholder="* Cell Phone Number"
                                            onChange={handlePhone} />

                                    </div>
                                    {phoneRequiredState ? null : (
                                        <label className="error">
                                            Phone is required and must be a number.
                                        </label>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Col className="d-flex justify-content-center row">
                            <Row className={phone?.length <= 11 ? "pt-0 register-disable pb-0 justify-content-center text-center" : "pt-0 pb-0"}>
                                <Form.Check.Label className={path === '/private/create-consignor' ? "text-dark" : "text-white"}>
                                    <Form.Check.Input
                                        // defaultValue="false"
                                        type="checkbox"
                                        defaultChecked={false}
                                        onChange={e => handleAgree(e)}
                                    ></Form.Check.Input>
                                    <span className={path === '/private/create-consignor' ? "form-check-sign" : "form-check-sign form-check-sign-white "}></span>
                                    I Agree to the: <a target="_blank" href="https://pdfhost.io/v/OQgBwYiNg_Level_Nine_Sports_Consignment_Contract" className="text-white"><u>Consignment Contract</u></a>
                                </Form.Check.Label>
                            </Row>
                            {agreeRequiredState ? null : (
                                <label className="error-agree">
                                    Agreeing to the consignment contract is required.
                                </label>
                            )}
                        </Col>
                        <Row className="d-flex flex-column justify-content-center">
                            <Col className='justify-content-center' >
                                <div className={phone?.length <= 11 || phone == undefined ? "register-disable card-footer text-center" : "card-footer text-center"}>
                                    <Button
                                        className="btn-fill btn-primary btn-wd text-light mx-3 pl-3 pr-3 p-1"
                                        type="submit"
                                        variant="default"
                                        onClick={handleSubmitCode}
                                    >
                                        Send Verification Code
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className='i-agree' style={{ marginTop: .9 + 'em' }}>
                            <Col lg="3" className={codeShow ? "justify-content-center pt-0 register-disable" : "pt-0 ml-0 "}>
                                <Form.Group
                                    className={
                                        codeRequiredState ? "has-success" : "has-success"
                                    }
                                >
                                    <Form.Control
                                        placeholder="* Enter Code"
                                        onChange={handleCodeCheck} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="card-footer text-center">
                        <Button
                            disabled={buttonDisabled}
                            className="btn-fill btn-success btn-wd btn-med pl-6 pr-6 btn-wd"
                            type="submit"
                            variant="default"
                            onClick={handleSubmit}
                        >
                            Create Account
                        </Button>
                    </div>
                </Card>
            </Form >
        </>
    );
}

const mapDispatchToProps = {
    showLoader,
    hideLoader
}

export default connect(undefined, mapDispatchToProps)(ConsignorSignup);