import React, { useState, useEffect } from 'react';
import ListingTemplate from './Listing/ListingTemplate';
import Photos from './Listing/Photos';
import UrlUpload from './Listing/UrlUpload';
import Packages from './Listing/Packages';

import { Container, Row, Col, Card, Tab, Nav } from 'react-bootstrap';

function Listings() {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('activeTab') || 'listings'
  );

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // Store the activeTab in localStorage whenever it changes
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  return (
    <Container>
      <Row>
        <Col md="12">
          <Card.Body className="">
            <Tab.Container id="icons-tabs-example" defaultActiveKey={activeTab}>
              <Nav justify role="tablist" variant="pills" className="mb-0 p-0">
                <Nav.Item>
                  <Nav.Link
                    eventKey="listings" // Unique eventKey for Listings tab
                    className={`tab-button ${
                      activeTab === 'listings' ? 'active' : ''
                    }`}
                    onClick={() => handleTabChange('listings')}
                  >
                    Listings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="photos" // Unique eventKey for Photos tab
                    className={`tab-button ${
                      activeTab === 'photos' ? 'active' : ''
                    }`}
                    onClick={() => handleTabChange('photos')}
                  >
                    Photos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="urlUpload" // Unique eventKey for UrlUpload tab
                    className={`tab-button ${
                      activeTab === 'urlUpload' ? 'active' : ''
                    }`}
                    onClick={() => handleTabChange('urlUpload')}
                  >
                    URL Upload
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Package" // Unique eventKey for package tab
                    className={`tab-button ${
                      activeTab === 'Package' ? 'active' : ''
                    }`}
                    onClick={() => handleTabChange('Package')}
                  >
                    Package
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </Card.Body>
        </Col>
      </Row>
      <div className="content-container">
        {activeTab === 'listings' && <ListingTemplate />}
        {activeTab === 'photos' && <Photos />}
        {activeTab === 'urlUpload' && <UrlUpload />}
        {activeTab === 'Package' && <Packages />}
      </div>
    </Container>
  );
}

export default Listings;
