import React from "react";
import ReactDOM from "react-dom";
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import Register from "./views/Public/Register";
import PublicNavbar from "./layouts/Components/Navbars/PublicNavbar";
import PublicFooter from "./layouts/Components/Footers/PublicFooter";
import Print from "./views/Components/Print/Print";
import Printrpg from "./views/Components/Print/Printrgp";


import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/index.scss";


import Public from "./layouts/Public.js";
import Private from "./layouts/Private.js";
import { Fragment } from "react";

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <Switch>
        <Provider store={store}>
          <Route exact path="/" render={() =>
            <Fragment>
              <div className="wrapper wrapper-full-page">
                <PublicNavbar />
                <Register />
                <PublicFooter />
              </div>
            </Fragment>
          } />
          <Route path="/public" render={(props) => <Public {...props} />} />
          <Route path="/private" render={(props) => <Private {...props} />} />
          <Route component={Print} path="/print/:id/:price/:title/:createdBy" />
          <Route component={Printrpg} path="/printrpg/:id/:price/:title/:createdBy/:date" />
        </Provider>
      </Switch>
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById("root")
);
