import React from "react";
import { connect } from 'react-redux';
 
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";



const PrivateFooter = ({ username }) => {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {/* { username } */}
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {/* Accounting */}
                </a>
              </li>
              {/* <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Blog
                </a>
              </li> */}
            </ul>
            <p className="copyright text-center">
              © <script>document.write(new Date().getFullYear())</script>
              <a href="http://www.levelninesports.com">L9 Sports</a>, Your outdoor adventure supplier!
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}
const mapStateToProps = (reduxState) => {
  return {
    username: reduxState.username
  }
};
export default connect(mapStateToProps)(PrivateFooter);

