import Dashboard from "./views/Private/Dashboard";
import Search from "./views/Private/Search"
import Consignor from "./views/Private/Consignor"
import Accounting from "./views/Private/Accounting"
import CreateConsignor from "./views/Private/CreateConsignor";
import Returns from "./views/Private/Returns";
import WebReturns from "./views/Private/WebReturns"
import CreateUser from "./views/Components/CreateUser";
import Listings from "./views/Components/Listings";
import RetailSyncs from "./views/Components/RetailSyncs";
import retailCycleCount from "./views/Components/RetailCycleCount";
import Login from "./views/Public/Login";
import Register from "./views/Public/Register";
import ReturnGoods from "./views/Components/ReturnGoods";

// import LockScreen from "./views/Public/LockScreen";
//  db go live change
// import BRSearch from "./views/Components/BRSearch";
// import CS from "./views/Components/CS";
// import RetailReplenishment from "./views/Components/RetailReplenishment";

var routes = [
  {
    adminMenu: false,
    path: "/dashboard",
    layout: "/private",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },
  {
    adminMenu: false,
    path: "/consignor",
    layout: "/private",
    name: "Consignor",
    icon: "nc-icon nc-single-02",
    component: Consignor,
  },
  {
    adminMenu: false,
    path: "/search",
    layout: "/private",
    name: "Search Items",
    icon: "nc-icon nc-zoom-split",
    component: Search,
  },
  {
    adminMenu: false,
    path: "/create-consignor",
    layout: "/private",
    name: "Create Consignor",
    icon: "nc-icon nc-circle-09",
    component: CreateConsignor,
  },
  {
    adminMenu: false,
    path: "/returns",
    layout: "/private",
    name: "In store purchase returns",
    icon: "nc-icon nc-money-coins",
    component: Returns,
  },
  {
    adminMenu: false,
    path: "/web-order-returns",
    layout: "/private",
    name: "Online purchase returns",
    icon: "nc-icon nc-cart-simple",
    component: WebReturns,
  },
  // {
  //   adminMenu: false,
  //   path: "/retail-replenishment",
  //   layout: "/private",
  //   name: "Retail Replenishment",
  //   icon: "nc-icon nc-delivery-fast",
  //   component: RetailReplenishment,
  // },
  {
    adminMenu: false,
    path: "/return-goods-program",
    layout: "/private",
    name: "RG Products",
    icon: "nc-icon nc-tag-content",
    component: ReturnGoods,
  },
  {
    adminMenu: true,
    path: "/users",
    layout: "/private",
    name: "Users",
    icon: "nc-icon nc-badge",
    component: CreateUser,
  },
  {
    adminMenu: true,
    path: "/accounting",
    layout: "/private",
    name: "Accounting",
    icon: "nc-icon nc-chart-bar-32",
    component: Accounting,
  },
  // {
  //   adminMenu: false,
  //   path: "/cs",
  //   layout: "/private",
  //   name: "CS Tools",
  //   icon: "nc-icon nc-settings-90",
  //   component: CS,
  // },
  {
    adminMenu: true,
    path: "/listings",
    layout: "/private",
    name: "Listing",
    icon: "nc-icon nc-ruler-pencil",
    component: Listings,
  },
  // {
  //   adminMenu: false,
  //   path: "/brsearch",
  //   layout: "/private",
  //   name: "Internal Tools",
  //   icon: "nc-icon nc-settings-tool-66",
  //   component: BRSearch,
  // },
  {
    adminMenu: true,
    path: "/retail-syncs",
    layout: "/private",
    name: "Retail Sync",
    icon: "nc-icon nc-bullet-list-67",
    component: RetailSyncs,
  },
  {
    adminMenu: true,
    path: "/retail-cycle-count",
    layout: "/private",
    name: "Cycle Count",
    icon: "nc-icon nc-delivery-fast",
    component: retailCycleCount,
  },
  {
    path: "/login",
    layout: "/public",
    component: Login,
  },
  {
    adminMenu: false,
    path: "/register",
    layout: "/public",
    name: "Kiosk Mode",
    icon: "nc-icon nc-tv-2",
    component: Register,
  },
];
export default routes;
