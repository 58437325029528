import React, { useState, useEffect } from 'react';
import ConsignorTable from "../Components/ConsignorTable";
import axios from 'axios';

import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Consignor() {


  const [resultsTable, setResultsTable] = useState([]);
  useEffect(() => {
    (async () => {
      await axios.get('/cnor/default-table')
      .then((response) => {
        setResultsTable(response.data)
      }).catch(err => {
        console.log(('Error here',err.response.data))
      })
    })()
  }, []);

  // Setup for default table call  VS search table query SQL calls 
  const [searchQuery, setSearchQuery] = useState('');
  const [fnReqSearchQueryState, setfnReqSearchQueryState] = useState(true);

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value.trim());
    e.target.value ? setfnReqSearchQueryState(false) : setfnReqSearchQueryState(true);
  }


  // On NON search query reload the updated default rows based on true/false passed to CreateItems
  const defaultTableUpdated = async () => {
      await axios.get('/cnor/default-table')
      .then((response) => {
        setResultsTable(response.data)
      }).catch(err => {
        console.log((err.response.data))
      })
  }

  // Search query to be called from CreateItems child componoent based on true/false
  const search = async () => {
    await axios.post('/cnor/search-table', { searchQuery })
      .then((response) => {
        setResultsTable(response.data)
      }).catch(err => {
        console.log((err.response.data))
      })
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (!fnReqSearchQueryState) {
      //false uses search query
      search()
      return setfnReqSearchQueryState(false);
    }
    else {
      setfnReqSearchQueryState(true);
    }
  }

  return (
    <>
      <Container fluid className="pl-0 pr-0 ml-0 mr-0">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <Card.Header>
                <Card.Title as="h4">Consignor Lookup:</Card.Title>
                <Col md="12">
                  <Form action="#" method="#">
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <InputGroup>
                            <i className="mt-1 ml-1 nc-icon nc-zoom-split pl-2 pt-2 pr-2 border-top border-left border-bottom"></i>
                            <Form.Control
                              defaultValue=""
                              placeholder="Search..."
                              type="text"
                              className="mt-1"
                              onChange={handleSearchQuery}
                            ></Form.Control>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col md="3">
                            <Button
                              className="btn-wd"
                              type="submit"
                              variant="default"
                              onClick={handleSubmit}
                            >
                              Search
                            </Button>
                          </Col>
                          <Col md="3">
                            <Button
                              className="btn-wd bg-light text-dark"
                              type="submit"
                              variant="default"
                              onClick={defaultTableUpdated}
                              defaultValue="Reset"
                            >
                              Reset
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Card.Header>
              <Card.Header>
                <p className="card-category">Or find by most recently added:</p>
              </Card.Header>
              <Card.Body>
                <ConsignorTable
                  resultsTable={resultsTable}
                  defaultTableUpdated={defaultTableUpdated}
                  fnReqSearchQueryState={fnReqSearchQueryState}
                  search={search}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Consignor;
