import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateUsername } from '../../store/reducer';
 
import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Col,
} from "react-bootstrap";

const Login = (props) => {
  //Response error message with reset
  const [errorResponse, setErrorResponse] = useState('');
  const [errorState, setErrorState] = useState(true);
  const resetResponseError = () => (setErrorState(true))

  const [cardClasses, setCardClasses] = useState("card-hidden");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleUsernameChange = e => setUsername(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);
  
  
  const handleSubmit = async e => {
    e.preventDefault();
    await axios.post('/auth/login', {username, password})
      .then(() => {
        dispatch(updateUsername(username))
        props.history.push('/private/dashboard');
      }).catch(err => {
        setErrorState(false)
        setErrorResponse(err.response.data)
    })
  }

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  
  return (
    <>
      <div
        className="full-page section-image"
        data-color="blue"
        data-image={require("../../assets/img/full-screen-image-2.jpg").default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form action="" className="form" method="">
                <Card className={"card-login " + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">Login</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Email address</label>
                        <Form.Control
                          onChange={handleUsernameChange}
                          placeholder="Enter email"
                          type="email"
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Password</label>
                        <Form.Control
                          onChange={handlePasswordChange}
                          placeholder="Password"
                          type="password"
                        ></Form.Control>
                      </Form.Group>
                      {errorState ? null : (
                            <Alert variant="danger">
                                <button
                                    onClick={resetResponseError}
                                    aria-hidden={true}
                                    className="close"
                                    data-dismiss="alert"
                                    type="button"
                                >
                                    <i className="nc-icon nc-simple-remove"></i>
                                </button>
                                <span className="text-center">
                                    {/* <b>Woops - </b> */}
                                    <b>{errorResponse}</b>
                                </span>
                            </Alert>
                        )}
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className="btn-wd" type="submit" variant="default" onClick={handleSubmit}>
                      Login
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("../../assets/img/full-screen-image-1.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default Login;
