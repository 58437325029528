import React from "react";
import ConsignorSignup from "../Components/ConsignorSignup"

import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function CreateConsignor() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Create Consignor</Card.Title>
              </Card.Header>
              <Card.Body>
                  <ConsignorSignup />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateConsignor;
