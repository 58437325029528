import React, { useState, useEffect } from 'react';

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function RetailCycleCountTable({ id, sku, quantity, svQty, diff, classification, cost }) {
    return (
        <>
            <tr key={id} className="bg-light">
                <td>{sku}</td>
                <td>{quantity}</td>
                {svQty && (
                    <td>{svQty}</td>
                )}
                {diff && (
                    <td>{diff}</td>
                )}
                {classification && (
                    <td>{classification}</td>
                )}
                {cost && (
                    <td>{cost}</td>
                )}
            </tr>
        </>
    );
}

export default RetailCycleCountTable;