import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function WebReturnOrder({ id, sku, name, quantity, unitprice, unitpricetax, returnResponHandler, deleteLine, updateProductOptions, l9package }) {

    const [selectQty, setSelectQty] = useState(null);
    const [itemTotal, setItemTotal] = useState(null);
    const [itemSelectedQty, setItemSelectedQty] = useState(null);
    const [radioDisabled, setRadioDisabled] = useState(true);
    const [productOptions, setProductOptions] = useState('wh');

    const [returnReason, setReturnReason] = useState('');

    const [tableRowCss, setTableRowCss] = useState(null);

    const setSelectBoxQty = (quantity) => {
        const selectOption = [{ value: 0, label: 0 },]
        for (let i = 1; i <= quantity; i++) {
            selectOption.push({ value: i, label: i },)
        }
        return setSelectQty(selectOption)
    }

    const returnQtyHandler = (qty) => {
        var itemTotal = qty * unitpricetax
        setItemTotal(itemTotal)
        returnResponHandler({ id: sku, l9_package: l9package, name: name, qty: qty, unitPrice: unitprice, unitPriceTax: unitpricetax, total: itemTotal, productOptions: productOptions, returnReason: returnReason })
    }

    const changeOption = (val) => {
        updateProductOptions({ id: sku, l9_package: l9package, name: name, qty: itemSelectedQty, unitPrice: unitprice, unitPriceTax: unitpricetax, total: itemTotal, productOptions: val, returnReason: returnReason })
    }

    const changeReturnReason = (val) => {
        updateProductOptions({ id: sku, l9_package: l9package, name: name, qty: itemSelectedQty, unitPrice: unitprice, unitPriceTax: unitpricetax, total: itemTotal, productOptions: productOptions, returnReason: val })
    }

    const packageHighlighter = () => {
        if (l9package === true) {
            setTableRowCss("danger")
        }
    }

    useEffect(() => {
        setSelectBoxQty(quantity)
        packageHighlighter()
    }, []);

    return (
        <>
            <tbody>
                <tr className={tableRowCss} key={id}>
                    <td>{sku}</td>
                    <td>{name}</td>
                    <td>{quantity}</td>
                    <td className="text-center">${Number(unitprice).toFixed(2)}</td>
                    <td className="text-center">${Number(unitpricetax).toFixed(2)}</td>
                    <td className="text-center">
                        <Form.Group className="pb-0 mb-0">
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Payment Option"
                                onChange={(value) => {
                                    if (value.value === 0) {
                                        deleteLine({ id: sku })
                                        setItemTotal(null)
                                        //reset background to initial order load state
                                        if (l9package === true) {
                                            setTableRowCss("danger")
                                        } else {
                                            setTableRowCss(null)
                                        }
                                        setRadioDisabled(true)
                                    } else {
                                        returnQtyHandler(value.value)
                                        setTableRowCss("success")
                                        setItemSelectedQty(value.value)
                                        setRadioDisabled(false)
                                    }
                                }}
                                options={selectQty}
                                placeholder="Select"
                            />
                        </Form.Group>
                    </td>
                    <td className="text-center font-weight-bold">${Number(itemTotal).toFixed(2)}</td>
                    <td>
                        <Form.Group className="pb-0 mb-0">
                            <Select
                                isDisabled={radioDisabled}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Payout Method"
                                onChange={(value) => {
                                    setReturnReason(value.value)
                                    changeReturnReason(value.value)
                                }}
                                options={[
                                    { value: "Wrong Size", label: "Wrong Size" },
                                    { value: "Incorrect Product Shipped", label: "Incorrect Product Shipped" },
                                    { value: "Dammaged/Defective", label: "Dammaged/Defective" },
                                    { value: "Arrived too late", label: "Arrived too late" },
                                    { value: "No longer needed", label: "No longer needed" },
                                    { value: "Did not match the description", label: "Did not match the description" },
                                    { value: "Gift Return", label: "Gift Return" },
                                    { value: "Better price somewhere else", label: "Better price somewhere else" },
                                    { value: "Customer wardrobing", label: "Customer wardrobing" },
                                    { value: "Damaged", label: "Damaged" },
                                ]}
                                placeholder="Select Reason"
                            />
                        </Form.Group>
                    </td>
                    <td className="text-center">
                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`}>
                                    <label className={radioDisabled === true ? "text-muted pr-4" : "text-dark font-weight-bold pr-4"}>
                                        <Form.Check
                                            className=''
                                            inline
                                            // label="WH"
                                            name="group1"
                                            type={type}
                                            id={`inline-radio-0`}
                                            disabled={radioDisabled}
                                            onChange={() => {
                                                setProductOptions('wh')
                                                changeOption('WH')
                                            }}
                                            defaultChecked
                                        />
                                        WH
                                    </label>
                                    <label className={radioDisabled === true ? "text-muted pr-4" : "text-dark font-weight-bold pr-4"}>
                                        <Form.Check
                                            inline
                                            // label="Keep"
                                            name="group1"
                                            type={type}
                                            id={`inline-radio-1`}
                                            disabled={radioDisabled}
                                            onChange={() => {
                                                setProductOptions('keep')
                                                changeOption('keep')
                                            }}
                                        />
                                        Keep
                                    </label>
                                    <label className={radioDisabled === true ? "text-muted pr-4" : "text-dark font-weight-bold pr-4"}>
                                        <Form.Check
                                            inline
                                            // label="Damaged"
                                            name="group1"
                                            type={type}
                                            id={`inline-radio-2`}
                                            disabled={radioDisabled}
                                            onChange={() => {
                                                setProductOptions('damaged')
                                                changeOption('damaged')
                                            }}
                                        />
                                        Damaged
                                    </label>
                                </div>
                            ))}
                        </Form>
                    </td>
                </tr>
            </tbody>
        </>
    );
}

export default WebReturnOrder;