import React, { useState, useEffect } from 'react';

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function RetailSyncTableCa({ id, sku, title, ca_error, svcode, bigcode, cacode, created_at, quantity }) {

    return (
        <>
            <tr id={id}>
                <td>{id}</td>
                <td>{sku}</td>
                <td>{title}</td>
                <td className="text-center">{quantity}</td>
                <td>{ca_error}</td>
                <td className="text-center">{svcode}</td>
                <td className="text-center">{bigcode}</td>
                <td className="text-center">{cacode}</td>
                <td className="text-center">{created_at}</td>
            </tr>
        </>
    );
}

export default RetailSyncTableCa;