import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import { useSelector } from 'react-redux';
import WebReturnOrder from './WebReturnOrder';

import {
	Alert,
	Badge,
	Button,
	Card,
	Form,
	Media,
	Navbar,
	Nav,
	Container,
	Row,
	Col,
	FormGroup,
	FormControl,
	Table,
} from "react-bootstrap";
import UsersList from './UsersList';

function WebReturn(props) {
	const notificationAlertRef = React.useRef(null);
	const notify = (place, type, msg) => {
		var type = type
		var options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>{msg}</b>
					</div>
				</div>
			),
			type: type,
			icon: "nc-icon nc-bell-55",
			autoDismiss: 10,
		};
		notificationAlertRef.current.notificationAlert(options);
	};

	// State
	const username = useSelector((reduxState) => reduxState.username);

	const [getorder, setgetorder] = useState('');

	const [fnRequiredState, setAsRequiredState] = useState(true);
	const [fnRequiredStateSubmit, setAsRequiredStateSubmit] = useState(true);

	const [showTable, setShowTable] = useState(false);

	const [orderResponse, setBigorder] = useState([]);
	const [orderId, setOrderId] = useState([]);
	const [dateCreated, setDateCreated] = useState([]);
	const [orderFirstName, setOrderFirstName] = useState([]);
	const [orderLastName, setOrderLastName] = useState([]);
	const [orderEmail, setOrderEmail] = useState([]);

	const [employeeName, setEmployeeName] = useState('');
	const [returnObj, setReturnObj] = useState([]);

	// Validators
	const isRequired = (value) => value !== null && value !== "" && value;

	const resetResponseError = () => (setErrorState(true))

	const handleGetOrder = (e) => {
		setgetorder(e.target.value);
		isRequired(e.target.value) ? setAsRequiredState(true) : setAsRequiredState(false);
	}
	const handleEmployee = (e) => {
		setEmployeeName(e.target.value);
		isRequired(e.target.value) ? setAsRequiredStateSubmit(true) : setAsRequiredStateSubmit(false);
	}

	const returnResponHandler = (val) => {
		if (returnObj.find((o) => o.id === val.id)) {
			let index = returnObj.indexOf(returnObj.find((o) => o.id === val.id))
			let updatedRetunObj = [...returnObj];
			updatedRetunObj.splice(index, 1, val)
			setReturnObj(updatedRetunObj)

		} else {
			let updatedRetunObj = [...returnObj];
			updatedRetunObj.push(val)
			setReturnObj(updatedRetunObj)
		}
	};

	const deleteLine = (val) => {
		let index = returnObj.indexOf(returnObj.find((o) => o.id === val.id))
		let updatedRetunObj = [...returnObj];
		updatedRetunObj.splice(index, 1)
		setReturnObj(updatedRetunObj)

	}

	const updateProductOptions = (val) => {
		let index = returnObj.indexOf(returnObj.find((o) => o.id === val.id))
		let updatedRetunObj = [...returnObj];
		updatedRetunObj.splice(index, 1, val)
		setReturnObj(updatedRetunObj)

	}

	const handleSubmit = e => {
		e.preventDefault();

		setBigorder([])
		setReturnObj([])
		setOrderId(null)
		setDateCreated(null)
		setOrderFirstName(null)
		setOrderLastName(null)
		setOrderEmail(null)
		setShowTable(false)

		let formNotFilled = 0
		if (!fnRequiredState || !isRequired(getorder)) {
			setAsRequiredState(false);
			formNotFilled++
		}
		if (formNotFilled > 0) {
			return
		}
		else {
			setAsRequiredState(true);
		}

		//1st CALL - BigCommerce Get Product Lines From Order
		axios.get(`/api/web/order/${getorder}`)
			.then((response) => {
				console.log('BigcOrder', response.data[0])
				setBigorder(response.data[0])
				setgetorder('')
			}).catch((response) => {
				notify("tc", "danger", `This orders was not found in BigCommerce! - ${response} `)
			})

		//2nd CALL - BigCommerce Get Customer Details From Order Details
		axios.get(`/api/web/order-details/${getorder}`)
			.then((response) => {
				setOrderId(response.data[0].id)
				setDateCreated(response.data[0].date_created)
				setOrderFirstName(response.data[0].billing_address.first_name)
				setOrderLastName(response.data[0].billing_address.last_name)
				setOrderEmail(response.data[0].billing_address.email)
				setShowTable(true)
			}).catch((err) => {
				props.hideLoader()
				notify("tc", "danger", `BigCommerce Get Order Details Call Has Failed`)
				console.log('Get Order Details Failed', err)
			})
	}

	//Send Payload to update Google Sheets then SkuVault
	const handleSubmitRefund = e => {
		e.preventDefault();
		let formNotFilledA = 0
		if (!fnRequiredStateSubmit || !isRequired(employeeName)) {
			setAsRequiredStateSubmit(false);
			formNotFilledA++
		}
		if (formNotFilledA > 0) {
			return
		}
		else {
			setAsRequiredStateSubmit(true);
		}

		//Google API Sheet Update > SkuVault Inventory
		axios.post('/api/web/sheets', { returnObj, orderId, dateCreated, orderFirstName, orderLastName, orderEmail, employeeName, username })
			.then(() => {
				axios.post('/api/web/inventory', { returnObj, username })
					.then((response) => {
						notify("tc", "success", `Return Process Completed - ${response.data.Status}`)
						setBigorder([])
						setReturnObj([])
						setOrderId(null)
						setDateCreated(null)
						setOrderFirstName(null)
						setOrderLastName(null)
						setOrderEmail(null)
						setShowTable(false)
					}).catch(() => {
						notify("tc", "warning", `Warning - Updating SkuVault Inventory had an issue, the return is still being processed. Please notifify IT`)
					})
			}).catch(() => {
				notify("tc", "warning", `There has been a problem updating Google Sheets - Please reaload the order and try again.`)
			})
	}

	return (
		<>
			<Container fluid>
				<div className="rna-container">
					<NotificationAlert ref={notificationAlertRef} />
				</div>
				<Form action="#" method="#">
					<Card className="card-plain mt-0">
						<div className="card-body">
							<Form.Group
								className={
									fnRequiredState ? "has-success" : "has-error"
								}
							>
								<Form.Control
									placeholder="Enter Order#"
									type="text"
									value={getorder}
									onChange={handleGetOrder}
								></Form.Control>
								{fnRequiredState ? null : (
									<label className="error pl-2">
										{returnObj}
										ORDER NUMBER IS REQUIRED
									</label>
								)}
							</Form.Group>
						</div>
						<div className="card-footer pt-0">
							<Button
								className="btn-fill btn-success btn-wd text-light"
								type="submit"
								variant="default"
								onClick={handleSubmit}
							>
								Get BigCommerce Order To Return
							</Button>
						</div>
					</Card>
				</Form>

				{showTable === true ? (<div>
					<Row>
						<Col md="12">
							<Card className="regular-table-with-color">
								<Row>
									<Col md="2">
										<Card.Header>
											<Card.Title as="h4">Order # {orderId} </Card.Title>
											<p className="card-category">
												Select Items To Return:
											</p>
										</Card.Header>

									</Col>
									<Col md="5">
										<Card.Header>
											<Card.Title as="h4">{orderFirstName} {orderLastName} - {orderEmail}</Card.Title>
											<p className="card-category">
												Order Created: {dateCreated}
											</p>
										</Card.Header>
									</Col>
									<Col md="5">
										<div className="card-body">
											<Form.Group
												className={
													fnRequiredStateSubmit ? "has-success" : "has-error"
												}
											>
												<Form.Control
													placeholder="Employee name"
													type="text"
													value={employeeName}
													onChange={handleEmployee}
												></Form.Control>
												{fnRequiredStateSubmit ? null : (
													<label className="error pl-2">
														PLEASE ENTER YOUR NAME HERE
													</label>
												)}
											</Form.Group>
										</div>
									</Col>
								</Row>
								<Card.Body className="table-responsive p-0" key={'retTable'}>
									<Table className="table-hover mb-0">
										<thead>
											<tr>
												<th width="100px">SKU</th>
												<th width="350px">Product Name</th>
												<th width="50px" className="text-center">Qty</th>
												<th width="100px" className="text-center">Unit price</th>
												<th width="100px" className="text-center">Unit Price W/ Tax</th>
												<th width="200px" className="text-center">Units to refund</th>
												<th width="100px" className="text-center">Refund Item Total</th>
												<th width="280px" className="text-center">Reason</th>
												<th width="300px" className="text-center">Product Options</th>
											</tr>
										</thead>
										{orderResponse.map((order) =>
											<WebReturnOrder
												id={order.id}
												sku={order.sku}
												name={order.name}
												unitprice={order.price_ex_tax}
												unitpricetax={order.price_inc_tax}
												quantity={order.quantity}
												l9package={order.l9_package}
												returnResponHandler={returnResponHandler}
												deleteLine={deleteLine}
												updateProductOptions={updateProductOptions}
											/>
										)}
									</Table>
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Row>

										<Col className='text-right h4 mt-1 mb-0 font-weight-bold' md="6">
											Refund total: ${Number(returnObj.reduce((total, el) => total + el.total, 0)).toFixed(2)}
										</Col>
										<Col md="2">
											<Button
												className="btn-fill btn-success btn-wd text-light"
												type="submit"
												variant="default"
												onClick={handleSubmitRefund}
											>
												Submit Refund Request
											</Button>
										</Col>
										<Col className="text-center" md="4">
											{fnRequiredStateSubmit ? null : (
												<label className="text-danger pt-2">
													Please enter your name above to process this return
												</label>
											)}
											{employeeName}
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</div>) : (
					<div></div>
				)}
			</Container>
		</>
	);
}

const mapDispatchToProps = {
	showLoader,
	hideLoader
}

export default connect(undefined, mapDispatchToProps)(WebReturn);