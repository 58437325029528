import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
// import MiniSearch from "../../../views/Components/MiniSearch";
 
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
  Collapse,
} from "react-bootstrap";


const PrivateNavbar = () => {

  let history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const handleLogout = e => {
    e.preventDefault();

    axios.get('/auth/logout')
    .then(() => {
      history.push('/public/login');
    }).catch(err => console.log(err));
  }
  
  let path = window.location.pathname

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block text-light bg-medium border-light"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>

          </div>
          {/* <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button> */}
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            
            <Nav className={path === '/private/search' ? "d-none" : "nav mr-auto" } navbar>
                  {/* <i className="nc-icon nc-zoom-split pl-2 pr-2 pt-1"></i> */}
                  {/* <MiniSearch className="pl-2"/> */}
            </Nav>
            <Nav navbar>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default"
                >
                  <i className="nc-icon nc-bullet-list-67"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Dropdown.Item
                    href="https://www.skiutah.com/snowreport"
                    target="_blank"
                  >
                    <i className="fas fa-snowboarding"></i>
                    Snow Report
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    href="#pablo"
                    onClick={handleLogout}
                  >
                    <i className="nc-icon nc-button-power"></i>
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default PrivateNavbar;
