import React, { useState, useEffect } from 'react';
import Select from "react-select";
import Categories from './Static/category.json';
import Brands from './Static/brand.json';
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";

function ReturnGoodsAddItems({

    setCategory, setCatLabel, setBrand, setBrandLabel,
    handleProductTitle, handlePrice, handleProductNotes,
    titleRequiredState, priceRequiredState, handleSubmitItem, setqoh,
}) {

    // useEffect(() => {
    //     setPaymentMethod("")
    //     setPaymentRequiredState(null)
    //     setPayoutPercentage("")
    //     setPayoutRequiredState(null)
    // }, []);
    return (
        <>
            <Container fluid>
                <div className="section-image" >
                    <Container>
                        <Row>
                            <Col md="12" sm="6">
                                <Form action="" id="add-item-form" className="form" method="">
                                    <Card>
                                        <Card.Header>
                                            <Card.Header>
                                                <Card.Title as="h4">Add RG Item To LightSpeed {titleRequiredState}</Card.Title>
                                            </Card.Header>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col className="" md="6">
                                                    <Form.Group
                                                        className={
                                                            titleRequiredState ? "has-success" : "has-error"
                                                        }
                                                    >
                                                        <label>Product Title</label>
                                                        <Form.Control
                                                            onChange={handleProductTitle}
                                                            type="text"
                                                        ></Form.Control>
                                                        {titleRequiredState ? null : (
                                                            <label className="error">
                                                                Product Title Requred
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className="" md="6">
                                                    <Form.Group
                                                        className={
                                                            priceRequiredState ? "has-success" : "has-error"
                                                        }
                                                    >
                                                        <label>Price</label>
                                                        <Form.Control
                                                            onChange={handlePrice}
                                                            type="text"
                                                        >
                                                        </Form.Control>
                                                        {priceRequiredState ? null : (
                                                            <label className="error">
                                                                Price Required, must be a number
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="" md="6">
                                                    <Form.Group>
                                                        <label>Category</label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="Category"
                                                            onChange={(value) => {
                                                                setCategory(value.value)
                                                                setCatLabel(value.label)
                                                            }}
                                                            options={Categories}
                                                            placeholder="Category"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="" md="6">
                                                    <Form.Group>
                                                        <label>Brand</label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="Brand"
                                                            onChange={(value) => {
                                                                setBrand(value.value)
                                                                setBrandLabel(value.label)
                                                            }}
                                                            options={Brands}
                                                            placeholder="Brand"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <label>Initials</label>
                                                        <Form.Control
                                                            cols="80"
                                                            onChange={handleProductNotes}
                                                            type="text"
                                                            rows="4"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group className="">
                                                        <label>Item QTY</label>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="QOH"
                                                            placeholder="1"
                                                            onChange={(value) => {
                                                                setqoh(value.value)
                                                            }}                                                            
                                                            options={[
                                                                { value: 1, label: "1"},
                                                                { value: 2, label: "2" },
                                                                { value: 3, label: "3" },
                                                                { value: 4, label: "4" },
                                                                { value: 5, label: "5" },
                                                                { value: 6, label: "6" },
                                                                { value: 7, label: "7" },
                                                                { value: 8, label: "8" },
                                                                { value: 9, label: "9" },
                                                                { value: 10, label: "10" },
                                                            ]}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button
                                                className="btn-fill pull-right pl-5 pr-5"
                                                type="submit"
                                                variant="success"
                                                onClick={handleSubmitItem}
                                            >
                                                Add Item
                                            </Button>
                                            <div className="clearfix"></div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </>
    );
}

export default ReturnGoodsAddItems;
