import React from "react";

import {
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
} from "react-bootstrap";

function QuickLinks() {
    return (
        <>
            <Col className="ml-auto mr-auto mb-0" md="12">
                <Row>
                    <Col md="12">
                        <h4 className="title mb-1 mt-0">Quick Links</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body>
                                <Row>

                                    <Col lg="3" sm="6" className="text-center">
                                        <Button
                                            className="btn-outline btn-wd mr-1"
                                            variant="primary"
                                            href="/public/register"
                                        >
                                            <span className="btn-label pr-1">
                                                <i className="fas fa-desktop"></i>
                                            </span>
                                            Kiosk Mode
                                        </Button>
                                    </Col>

                                    <Col lg="3" sm="6" className="text-center">
                                        <Button
                                            className="btn-outline btn-wd mr-1"
                                            variant="success"
                                            href="/private/consignor"
                                        >
                                            <span className="btn-label pr-1">
                                                <i className="fas fa-plus-circle"></i>
                                            </span>
                                            Create Product
                                        </Button>
                                    </Col>

                                    <Col lg="3" sm="6" className="text-center">
                                        <Button
                                            className="btn-outline btn-wd mr-1"
                                            variant="warning"
                                            href="/private/create-consignor"
                                        >
                                            <span className="btn-label pr-1">
                                                <i className="fas fa-address-card"></i>
                                            </span>
                                            Create Consigner
                                        </Button>
                                    </Col>

                                    <Col lg="3" sm="6" className="text-center">
                                        <Button
                                            className="btn-outline btn-wd mr-1"
                                            variant="info"
                                            href="/private/search"
                                        >
                                            <span className="btn-label pr-1">
                                                <i className="fas fa-address-book"></i>
                                            </span>
                                            Product Detailed
                                        </Button>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </>
    );
}

export default QuickLinks;