import React, { Component } from 'react'
import axios from 'axios';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment'
import { CSVLink, CSVDownload } from "react-csv";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import { CSVReader } from 'react-papaparse';

import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  FormControl,
  Table,
  ProgressBar,
} from "react-bootstrap";

class Accounting extends Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDownloaded: false,
      consignmentItems: [],
      from: undefined,
      to: undefined,
      itemID: '',
      paidDate: 'todaysdate',
      errorState: false,
      errorResponse: '',
      errorStateSales: false,
      errorResponseSales: '',
      itemsUpdated:'',
      paidSales: [],
      loader: 0,
    }
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    // this.state = this.getInitialState();

  }

  getConsignSales = () => {
    this.props.showLoader()
    const { from, to } = this.state
    let fromDate = moment(from).set({ hour: '00', minute: '00', second: '01' }).format()
    let toDate = moment(to).set({ hour: '23', minute: '59', second: '59' }).format()
    // console.log(fromDate, toDate)
    axios.post(`/acct/consignsales`, { fromDate, toDate })
      .then((response) => {
        console.log('Raw Response Data', response.data)
        this.props.hideLoader()

        if (response.status === 204) {
          this.setState({ errorState: true });
          this.setState({ errorResponse: "No sales found for selected timeframe =(" });
          return console.log('No sales for selected timeframe.')
        }

        let skuArrayWithFields = []
        response.data.lineItemsArray.map(function (items, i) {
          // Deal with items that have ZERO custom fields
          if (typeof items.Item.CustomFieldValues != 'undefined') {
            skuArrayWithFields.push(
              {
                items: items.Item,
                orderPrice: response.data.orderPriceArray[i]
              }
            )
          } else {
            console.log(`ITEMS MISSING ALL CUSTOM FIELDS: ${items.Item.itemID}`)
          }
        })

        let toExport = []

        //notes: 1400 below is the dollar amount on the sale. We pay customer 75% up to $350 Cap. 350 is 25% of $1400
        // function below is to cacualte the 75% we owe the customer
        skuArrayWithFields.map((obj) => {
          const base = obj.orderPrice.subTotal
          const percent = obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Consignment %").map(names => names.value)
          const paymentType = obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Payment Type").map(names => names.value)
          
          //if statement for tent sale

          if(paymentType == 'tent-sale' && base <= 1000){
            var payout = (base / 100) * percent
            var payoutSend = payout.toFixed(2)
            console.log('less than 1000 tent sale', payoutSend)
          } 
          if(paymentType == 'tent-sale' && base > 1000 ) {
              var payoutSend = base - 250
            console.log('greater than 1000 tent sale', payoutSend)
          } else {
            var payout = (base / 100) * percent
            var payoutSend = payout.toFixed(2)
          }

          //if statement for full bike max 350

          if(paymentType == 'full-bike' && base <= 1400 || paymentType == 'full-bike-store-credit' && base <= 1400){
            var payout = (base / 100) * percent
            var payoutSend = payout.toFixed(2)
            console.log('less than 1400', payoutSend)
          }
          if(paymentType == 'full-bike' && base > 1400 || paymentType == 'full-bike-store-credit' && base > 1400) {
            var payoutSend = base - 350
            console.log('greater than 1400', payoutSend)
          } 
          else {
            var payout = (base / 100) * percent
            var payoutSend = payout.toFixed(2)
            console.log('last else', payoutSend)
          }          

          console.log('ob',obj)
          toExport.push({
            ItemID: obj.items.itemID,
            Description: obj.items.description,
            Base_Price: obj.items.Prices.ItemPrice[0].amount,
            Price_Paid: obj.orderPrice.subTotal,
            Parent_Order: obj.orderPrice.saleID,
            E_Mail: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "e-mail").map(names => names.value),
            Phone: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Phone").map(names => names.value),
            First_Name: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "First Name").map(names => names.value),
            Last_Name: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Last Name").map(names => names.value),
            Address: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Address").map(names => names.value),
            City: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "City").map(names => names.value),
            State: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "State").map(names => names.value),
            Zip: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Zip").map(names => names.value),
            Rate: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Consignment %").map(names => names.value),
            P_Method: paymentType,
            Item_Created: obj.items.createTime,
            Archived: obj.items.archived,
            Paid_On_Date: obj.items.CustomFieldValues.CustomFieldValue.filter((names) => names.name === "Customer Paid On").map(names => names.value),
            Payout: payoutSend,
            Pay_On_Item: 'FALSE'
          })
        })
        this.setState({ consignmentItems: toExport });
        this.setState({ isDownloaded: true });
      }).catch(error => {

        this.props.hideLoader()
        console.log('Error with get sales', error)
        this.setState({ errorState: true });
        this.setState({ errorResponse: [error.response?.request.response, "- Please try another date range"] });
      });
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState({
      from: undefined,
      to: undefined,
      isDownloaded: false,
    }
    );
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleMarkAsPaid = () => {
    // this.props.showLoader()

    const { paidSales } = this.state;

    const batches = []
    while (paidSales.length) {
      batches.push(paidSales.splice(0, 3))
    }
    console.log(batches)

    function* runApiCallsOne() {
      for (let i = 0; i < batches.length; i++) {
        yield axios.put(`/acct/eol/`, batches[i])
      }
      return "Pay sales finished"
    }

    let paidSalesArray = []

    const generator = runApiCallsOne();
    let runBatchMarkPaid = () => {
      try {
        const p = generator.next();
        if (p.done) {
          console.log('all done', paidSalesArray)
          this.setState({ errorStateSales: true });
          this.setState({ errorResponseSales: `Items updated: ${paidSalesArray}.` });
          return showReults()
        }
        p.value.then((d) => {
          const loadAmt = (100 / batches.length)
          const currentLoad = this.state.loader
          const loadUpdate = loadAmt + currentLoad
          this.setState({ loader: loadUpdate })
          // paidSalesArray.push(d.data)
          let res = d.data
          for (const key in res) {
            paidSalesArray.push(res[key].itemID)
            this.setState({itemsUpdated: res[key].itemID});
          }

          runBatchMarkPaid()
        });
      }
      catch (err) {
        return console.log(err.status);
      }

    }
    runBatchMarkPaid()
  }

  resetResponseError = () => {
    this.setState({ errorState: false })
  }

  resetSalesResponse = () => {
    this.setState({ errorStateSales: false })
  }

  handleOnDrop = (data) => {
    console.log('---------------------------');
    for(let i = 0; i < data.length; i++){
      // console.log(typeof data[i].data.ItemID)
      if(data[i].data.ItemID == ''){
        data.splice(i, 1)
      }
    }
    console.log('updated',data)
    this.setState({ paidSales: data });
    console.log('---------------------------');
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  componentDidMount() {
    axios.get('/auth/user')
      .then((response) => {
        const { isAdmin } = response.data
        if (!isAdmin) this.props.history.push('/public/login');
      })
  };

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Commissions Report</Card.Title>
                </Card.Header>
                <Card.Body className="ml-4">
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col>
                        <span className="text-secondary">
                        {!from && !to && 'Select start day'}
                        {from && !to && 'Select end day'}
                      </span>
                      <p className="text-warning">
                        {from &&
                          to &&
                          `Selected from ${from.toLocaleDateString()} to
                          ${to.toLocaleDateString()}`}{' '}
                        {from && to && (
                          <Button size="sm" variant="default" onClick={this.handleResetClick}>
                            Reset
                          </Button>
                        )}
                      </p>

                      <DayPicker
                        className="Selectable"
                        numberOfMonths={this.props.numberOfMonths}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                      />
                      <Helmet>
                        <style>{`
                        .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                        }
                        .Selectable .DayPicker-Day {
                            border-radius: 0 !important;
                        }
                        .Selectable .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                        }
                        .Selectable .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                        `}</style>
                      </Helmet>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <Button onClick={this.getConsignSales}>Get Consignment Sales</Button>
                      {!this.state.errorState ? null : (
                        <Alert variant="danger">
                          <button
                            onClick={this.resetResponseError}
                            aria-hidden={true}
                            className="close"
                            data-dismiss="alert"
                            type="button"
                          >
                            <i className="nc-icon nc-simple-remove"></i>
                          </button>
                          <span className="text-center">
                            {/* <b>Woops - </b> */}
                            <b>{this.state.errorResponse}</b>
                          </span>
                        </Alert>
                      )}
                      <h4>{this.state.isDownloaded ?
                        <CSVLink
                          filename={"L9-consignment-sales.csv"}
                          data={this.state.consignmentItems}><Button variant="success">Download Report</Button></CSVLink>
                        : null}</h4>                          
                        </Col>
                      </Row>                      
                    </Col>
                    <Col className="" md="6">
                      <span className="text-secondary">Post Paid Sales</span>
                      <Row>
                        <Col className="mt-2 mr-2">
                          <CSVReader
                            onDrop={this.handleOnDrop}
                            onError={this.handleOnError}
                            config={{ header: true }}
                            addRemoveButton
                            onRemoveFile={this.handleOnRemoveFile}
                            style={{
                              dropArea: {
                                borderColor: '#007bff',
                                borderRadius: 10,
                                height: 200,
                              },
                              dropAreaActive: {
                                borderColor: '#e83e8c',
                              },
                              dropFile: {
                                width: 300,
                                height: 125,
                                // background: '#ccc',
                              },
                              fileSizeInfo: {
                                color: '#fff',
                                backgroundColor: '#28a745',
                                borderRadius: 3,
                                lineHeight: 2,
                                marginBottom: '0.5em',
                                padding: '0 0.4em',
                              },
                              fileNameInfo: {
                                color: '#fff',
                                backgroundColor: '#6c757d',
                                borderRadius: 3,
                                fontSize: 14,
                                lineHeight: 2,
                                padding: '0 0.4em',
                              },
                              removeButton: {
                                color: 'red',
                              },
                              progressBar: {
                                padding: '0.5em 0',
                                padding: '0 0.4em',
                                backgroundColor: 'orange',
                              }
                            }}
                          >
                            <span>Drop CSV file here or click to upload.</span>
                          </CSVReader>

                        </Col>
                      </Row>
                      <div>
                        <ProgressBar animated now={this.state.loader} variant="success" />
                        {!this.state.errorStateSales ? null : (
                          <Alert className="mt-3" variant="primary">
                            <button
                              onClick={() => {
                                this.resetSalesResponse();
                                this.setState({loader:0});
                              }}
                              aria-hidden={true}
                              className="close"
                              data-dismiss="alert"
                              type="button"
                            >
                              <i className="nc-icon nc-simple-remove"></i>
                            </button>
                            <span className="text-center">
                              {/* <b>Woops - </b> */}
                              <b> {this.state.errorResponseSales}</b>
                            </span>
                          </Alert>
                        )}                        
                        <Button className="form-button mt-3 mr-3" onClick={this.handleMarkAsPaid}>Mark As Paid</Button>
                        <b>{this.state.itemsUpdated}</b>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
const mapDispatchToProps = {
  showLoader,
  hideLoader
}

export default connect(undefined, mapDispatchToProps)(Accounting);