import React, { useState, useEffect, useRef } from 'react';

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import ReactTable from "./ReactTable/ReactTableOne";
import { downloadExcel } from "react-export-table-to-excel";

function RetailCycleCountRTable({ data }) {
    // const [dataTable, setDataTable] = useState([]);
    // useEffect(() => {
    //     setDataTable(sku, quantity, svQty, diff, classification, cost)
    // }, []);

    // const [data, setData] = React.useState(
    //     dataTable.map((prop, key) => {
    //         return {
    //             id: key,
    //             sku: prop[0],
    //             cycleqty: prop[1],
    //             svqty: prop[2],
    //             diff: prop[3],
    //             classification: prop[4],
    //             cogs: prop[5],
    //             // actions: (
    //             //     // we've added some custom button actions
    //             //     <div className="actions-right">
    //             //         {/* use this button to add a like kind of action */}
    //             //         <Button
    //             //             variant="danger"
    //             //             size="sm"
    //             //             className="btn-link remove text-danger"
    //             //         >
    //             //             <i className="fa fa-times" />
    //             //         </Button>{" "}
    //             //     </div>
    //             // ),
    //         };
    //     })
    // );

    // const { onDownload } = useDownloadExcel({
    //     currentTableRef: tableRef.current,
    //     filename: 'Users table',
    //     sheet: 'Users'
    // })

    // const tableRef = useRef(null);

    // const { onDownload } = useDownloadExcel({
    //     currentTableRef: tableRef.current,
    //     filename: 'Users table',
    //     sheet: 'Users'
    // })

    const header = ["sku", "cycleqty", "svqty", "diff", "classification", "cogs"]

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Cycle-Count-Diff-Export",
            sheet: "Exported-Cycle-Diff",
            tablePayload: {
                header,
                body: data
            },
        });
    }

    return (
        <>
            <button onClick={handleDownloadExcel}>download excel</button>
            <ReactTable
                data={data}
                columns={[
                    {
                        Header: "SKU",
                        accessor: "sku"
                    },
                    {
                        Header: "CYCLEQTY",
                        accessor: "cycleqty"
                    },
                    {
                        Header: "SVQTY",
                        accessor: "svqty"
                    },
                    {
                        Header: "DIFF",
                        accessor: "diff"
                    },
                    {
                        Header: "CLASSIFICATION",
                        accessor: "classification"
                    },
                    {
                        Header: "COGS",
                        accessor: "cogs"
                    }
                ]}
                className="-striped -highlight warning-pagination"
            />
        </>
    );
}

export default RetailCycleCountRTable;