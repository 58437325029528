import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

function UsersList({ id, username, is_admin, userReload }) {

    //Delete setup
    const [alert, setAlert] = useState(null);
    const handleSubmitDelteItem = e => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => successDelete(e)}
                onCancel={() => cancelDetele()}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="info"
                confirmBtnText="Yes, delete item!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover user data
            </SweetAlert>
        );
    };
    //Delete System User 
    const successDelete = e => {
        
        axios.delete(`/auth/users/${id}`)
            .then(() => {
                userReload()
                hideAlert()
            }).catch(err => {
                console.log(err)
            })
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                No problem, we all press buttons by mistake!
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };


    return (
        <>
            {alert}
            <tbody key={id}>
                <tr>
                    <td className="text-left">
                        {id}
                    </td>
                    <td className="text-left">
                        {username}
                    </td>
                    <td className="text-left">
                        {is_admin === true ? "TRUE" : "FALSE" }
                    </td>
                    <td className="td-actions text-center">
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-1">Are you sure you want to Delete?</Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs"
                                variant="danger"
                                onClick={() => {
                                    handleSubmitDelteItem(id);
                                }}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            </tbody>
        </>
    );
}


export default UsersList;