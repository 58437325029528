import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';

const PackageEditor = () => {
    const [type, setType] = useState('')
    const [count, setCount] = useState(null)
    const [mainFile, setMainFile] = useState('')
    const [mainUrl, setMainUrl] = useState('')
    const [bootFile, setBootFile] = useState('')
    const [bootUrl, setBootUrl] = useState('')
    const [bindingFile, setBindingFile] = useState('')
    const [bindingUrl, setBindingUrl] = useState('')
    const [bootBindingFile, setBootBindingFile] = useState('')
    const [bootBindingUrl, setBootBindingUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [sku, setSku] = useState('')
    const [flag, setFlag] = useState(false)
    const [saveAsNew, setSaveAsNew] = useState(false)
    const [imageNumber, setImageNumber] = useState('')

    const [displayImage, setDisplayImage] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        if (saveAsNew) {
            handleSubmit();
        }
    }, [saveAsNew]);

    const handleSubmit = async e => {
        if (count === '1' && mainFile.length === 0 && mainUrl=='') {
            alert('Please attatch all files');
            return;
        }
        if (count === '2' && ((mainFile.length === 0 && mainUrl == '') || (bootBindingFile.length === 0 && bootBindingUrl == ''))) {
            alert('Please attatch all files');
            return;
        }
        if (count === '3' && ((mainFile.length === 0 && mainUrl == '' )|| (bootFile.length === 0 && bootUrl == '')||(bindingFile.length === 0 && bindingUrl == ''))) {
            alert('Please attatch all files');
            return;
        }
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("mainFile", mainFile[0]);
          formData.append("bootFile", bootFile[0]);
          formData.append("bindingFile", bindingFile[0]);
          formData.append("bootBindingFile", bootBindingFile[0]);
          formData.append("type", type);
          formData.append("count", count);
          formData.append("mainUrl", mainUrl);
          formData.append("bootUrl", bootUrl);
          formData.append("bindingUrl", bindingUrl);
          formData.append("bootBindingUrl", bootBindingUrl);
          formData.append("sku", sku);
          formData.append("flag",flag);
          formData.append("saveAsNew", saveAsNew)

            
          
    
         // Make the POST request
          const response = await axios.post(
            //deployed server
            "https://l9golf.com/packageBuilder",
            //local server
            // "http://127.0.0.1:5000/packageBuilder",
            formData,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
            console.log(response.data.displayImage);
            console.log(response.data.flag)
            console.log(response.data.error);
            setImageNumber(response.data.imageNumber)

            setDisplayImage(response.data.displayImage);
            if (response.data.error) {
                setError(response.data.error);
                if (response.data.flag) setFlag(true);
            }
            else {
                setError('');
                setFlag(false);
            }
            setIsLoading(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };
    const deleteImage = async e => {
        setFlag(false);
        
        e.preventDefault()
        
        var formData = new FormData();
        formData.append("sku", sku);
        formData.append("imageNumber", imageNumber);

        const headers = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
        }
        fetch('https://l9golf.com/DeleteSingleImage', headers)
        // fetch('http://127.0.0.1:5000/DeleteSingleImage', headers)
            .then(response => response)
            .then(response => {
                console.log(response,'response.data', ': Response');
                setDisplayImage(null);
                setError("Deleted");
            }).catch(error => console.log('HandleSubmit Error:', error)).finally(() => {
            })


    }
    function saveAsNewImage() {
        setSaveAsNew(true);
    }


    
  return (
    <Container>
            <h2>Package Builder</h2> 
            <div className='row'>
                
            <div className='col-sm-4'>
            <input className="form-control" type="text" name="sku" placeholder="SKU" onChange={(event) => setSku(event.target.value)} />

            <label className="form-check-label">Package Type:</label>        
            <div className='form-check'> 
                <input className='form-check-input' type='radio' name='type' value='Ski' onClick={(e) => setType(e.target.value)} /> Ski  
            </div>
            <div className='form-check'> 
            <   input className='form-check-input' type='radio' name='type' value='Board' onClick={(e) => setType(e.target.value)} /> Board
            </div>
            {type && sku &&(
                <div>
                    <label>Item Count:</label>
                    {type === 'Ski' && (
                        <div className='form-check'>
                            <input className='form-check-input' type='radio' name='count' value={1} onClick={(e) => setCount(e.target.value)} onChange={(event) => {
                                setMainUrl('');
                                setMainFile('');
                                setBootBindingUrl('');
                                setBootBindingFile('');
                                setBootUrl('');
                                setBootFile('');
                                setBindingUrl('');
                                setBindingFile('');
                            }}/> 1
                        </div>
                    )}
                    <div className='form-check'>
                    <input className='form-check-input' type='radio' name='count' value={2} onClick={(e) => setCount(e.target.value)} onChange={(event) => {
                                setMainUrl('');
                                setMainFile('');
                                setBootBindingUrl('');
                                setBootBindingFile('');
                                setBootUrl('');
                                setBootFile('');
                                setBindingUrl('');
                                setBindingFile('');
                            }}/> 2
                    </div>
                    <div className='form-check'>
                    <input className='form-check-input' type='radio' name='count' value={3} onClick={(e) => setCount(e.target.value)} onChange={(event) => {
                                setMainUrl('');
                                setMainFile('');
                                setBootBindingUrl('');
                                setBootBindingFile('');
                                setBootUrl('');
                                setBootFile('');
                                setBindingUrl('');
                                setBindingFile('');
                            }}/> 3
                    </div>
                    
                </div>
            )}
            </div>
            {count === '1' && type === 'Ski' && (
                <div className='col-md-6'>
                    <label className='form-label' >{type} URL</label>
                    <input
                            placeholder={`${type} URL`}
                            className="form-control"
                            type="text"
                            name="mainUrl"
                            onChange={(event) => {
                                setMainUrl(event.target.value);
                                setMainFile('');
                            }}
                            disabled={mainFile.length > 0 }                           
                        />
                        <label className="form-label" >Or</label>
                        <input
                            type="file"
                            name='mainFile'
                            className="form-control"
                            onChange={(event) => {
                                setMainFile(event.target.files);
                                setMainUrl('');
                            }}
                            disabled={mainUrl !== ''}
                        />
                </div>
            )}
            {count === '2' && (
                <div className='col-md-8'>
                    <div className='row'>
                        <div className='col-sm-2 mt-auto text-right' >
                            <label className='form-label' >{type}:</label>
                        </div>
                        <div className='col'>
                        <input
                            placeholder={`${type} URL`}
                            className="form-control"
                            type="text"
                            name="mainUrl"
                            onChange={(event) => {
                                setMainUrl(event.target.value);
                                setMainFile('');
                            }}
                            disabled={mainFile.length > 0 }                           
                        />
                        </div>
                        <div className='col'>
                            <input
                                type="file"
                                name='mainFile'
                                className="form-control"
                                onChange={(event) => {
                                    setMainFile(event.target.files);
                                    setMainUrl('');
                                }}
                                disabled={mainUrl !== ''}
                            />
                        </div>  
                    </div>
                    <div className='row mt-3'>
                        <div className='col-sm-2 text-right mt-auto'>
                            <label className='form-label' >Boot/Binding:</label>
                        </div>
                        <div className='col'>
                            <input
                                placeholder="Boot/Binding URL"
                                className="form-control"
                                type="text"
                                name="bootBindingUrl"
                                onChange={(event) => {
                                    setBootBindingUrl(event.target.value);
                                    setBootBindingFile('');
                                }}
                                disabled={bootBindingFile.length > 0 }                           
                            />
                        </div>
                        <div className='col'>
                            <input
                                type="file"
                                name='bootBindingFile'
                                className="form-control"
                                onChange={(event) => {
                                    setBootBindingFile(event.target.files);
                                    setBootBindingUrl('');
                                }}
                                disabled={bootBindingUrl !== ''}
                            />
                        </div>
                    </div>
                </div>
            
                
            )}
            {count === '3' && (
                <div className='col-md-8'>
                    <div className='row'>
                        <div className='col-sm-2 mt-auto text-right' >
                            <label className='form-label' >{type}:</label>
                        </div>
                        <div className='col'>
                            <input
                                    placeholder={`${type} URL`}
                                    className="form-control"
                                    type="text"
                                    name="mainUrl"
                                    onChange={(event) => {
                                        setMainUrl(event.target.value);
                                        setMainFile('');
                                    }}
                                    disabled={mainFile.length > 0 }                           
                                />
                        </div>
                        <div className='col'>
                            <input
                                type="file"
                                name='mainFile'
                                className="form-control"
                                onChange={(event) => {
                                    setMainFile(event.target.files);
                                    setMainUrl('');
                                }}
                                disabled={mainUrl !== ''}
                            />
                        </div>
                    </div>

                    <div className='row mt-3'>
                    <div className='col-sm-2 text-right mt-auto'>
                    <label className='form-label' >Boot:</label>
                    </div>
                    <div className='col'>
                    <input
                            placeholder='Boot URL'
                            className="form-control"
                            type="text"
                            name="mainUrl"
                            onChange={(event) => {
                                setBootUrl(event.target.value);
                                setBootFile('');
                            }}
                            disabled={bootFile.length > 0 }                           
                        />
                        </div>
                        <div className='col'>
                        <input
                            type="file"
                            name='mainFile'
                            className="form-control"
                            onChange={(event) => {
                                setBootFile(event.target.files);
                                setBootUrl('');
                            }}
                            disabled={bootUrl !== ''}
                        />
                    </div>
                    </div>
                    <div className='row mt-3'>
                    <div className='col-sm-2 text-right mt-auto'>
                    <label className='form-label' >Binding:</label>
                    </div>
                    <div className='col'>
                    <input
                            placeholder='Binding URL'
                            className="form-control"
                            type="text"
                            name="mainUrl"
                            onChange={(event) => {
                                setBindingUrl(event.target.value);
                                setBindingFile('');
                            }}
                            disabled={bindingFile.length > 0 }                           
                        />
                        </div>
                        <div className='col'>
                        <input
                            type="file"
                            name='mainFile'
                            className="form-control"
                            onChange={(event) => {
                                setBindingFile(event.target.files);
                                setBindingUrl('');
                            }}
                            disabled={bindingUrl !== ''}
                        />
                        </div>
                    </div>
                </div>
            )}
            </div>
            {type && count && (
                <div className='mt-3'>
                    <button className='btn btn-primary' onClick={handleSubmit}>Create Package</button>
                </div>
            )
            }
            
            { isLoading  && 
            <div className="col-auto">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>
            }
            {error && (
                <div className='mt-3'>
                    <h3>{error}</h3>
                    {error === 'Duplicate Image. Would you like to overwrite the image?' && (
                    <div>
                        <button className='btn btn-danger' onClick={handleSubmit}>Overwrite?</button>
                        <button className='btn btn-secondary' onClick={saveAsNewImage}>Save as New</button>
                    </div>
                    )}
                </div>

            )}
            {displayImage && (
                        <div>
                            {/* button that allows you to copy the displayImage text to clipboard */}
                            <button
                                className="btn btn-primary mt-1 mb-1"
                                onClick={() => {
                                    navigator.clipboard.writeText(displayImage);
                                }}>
                                Copy Image URL
                            </button>
                            <h3>Thumbnail</h3>
                            <div>
                                {/* Date.now() is needed so that the page rerenders the new image if the user overwrites the old image */}
                                <img
                                    src={`${displayImage}?${Date.now()}`}
                                    alt="displayImage"
                                    style={{
                                        width: "250px",
                                        height: "250px",
                                        padding: "10px",
                                        margin: "10px",
                                    }}
                                />
                            </div>
                            <h3>Product Page</h3>
                            <div>
                                {/* Date.now() is needed so that the page rerenders the new image if the user overwrites the old image */}
                                <img
                                    src={`${displayImage}?${Date.now()}`}
                                    alt="displayImage"
                                    style={{
                                        width: "700px",
                                        height: "700px",
                                        padding: "10px",
                                        margin: "10px",
                                    }}
                                />
                            </div>
                            <input
                                className="btn btn-danger mb-5"
                                type="button"
                                value={"Delete"}
                                onClick={deleteImage}
                            />
                        </div>
                    )}
    {type == 'Ski' &&(
        <div>
            <div className='row'>
                {count == 1 && (
                <div className='col-sm-4'>
                    <h3>Example Ski</h3>
                    <img src="https://bikewagonmedia.com/media/L9/Guide/guide ski.webp" className='img-fluid' alt='img1' />
                </div>
                )}
                {count > 1 && (
                    <div className='col-sm-4'>
                        <h3>Example Ski</h3>
                        <img src="https://bikewagonmedia.com/media/L9/2023-09-26/test_Img1.jpg?1695760865519" className='img-fluid' alt='img1' />
                    </div>
                    )}
            {count >= 2 && (
                <div className='col-sm-4'>
                    <h3>Image 2 Example</h3>
                    <img src="https://bikewagonmedia.com/media/L9/2023-07-28/PNTZ01999_1.jpg" className='img-fluid' alt='img2' />
                </div>
            )}
                {count >= 3 && (
                    <div className='col-sm-4'>
                        <h3>Image 3 Example</h3>
                        <img src='https://bikewagonmedia.com/media/L9/2023-08-28/PNTZ02505_BlackSilver_1.jpg' className='img-fluid' alt='img3' />
                    </div>
                )}
            
        </div>
        </div>
        )}
    {type == 'Board' &&(
        <div>
            <div className='row'>
                {count >= 1 && (
                    
                <div className='col-sm-4'>
                    <h3>Board Example</h3>
                    <img src="https://bikewagonmedia.com/media/L9/2023-07-07/goldenOrca_Img1.jpg" className='img-fluid' alt='img1' />
                </div>
                )}
            
            {count >= 2 && (
                <div className='col-sm-4'>
                    <h3>Image 2 Example</h3>
                    <img src="https://bikewagonmedia.com/media/L9/2023-07-28/PNTZ02013_OlvNigRos_1.jpg" className='img-fluid' alt='img2' />
                </div>
            )}
                {count >= 3 && (
                    <div className='col-sm-4'>
                        <h3>Image 3 Example</h3>
                        <img src="https://bikewagonmedia.com/media/L9/2023-07-28/PNTZ02023_Black_3.jpg" className='img-fluid' alt='img3' />
                    </div>
                )}
            </div>
        </div>
        )}

    </Container>


  )
}

export default PackageEditor