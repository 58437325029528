import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import RetailSyncTable from "./RetailSyncTable"
import RetailSyncTableBigC from "./RetailSyncTableBigC"
import RetailSyncTableCa from "./RetailSyncTableCa"
import NotificationAlert from "react-notification-alert";
// import InputGroup from 'react-bootstrap/InputGroup';


import {
	Alert,
	Badge,
	Button,
	Card,
	Form,
	Media,
	Navbar,
	Nav,
	Container,
	Row,
	Col,
	FormGroup,
	FormControl,
	Table,
	Tab,
} from "react-bootstrap";

function RetailSyncs() {
	// const [allowMillcreek, setAllowMillcreek] = useState(true);
	// const [allowOgden, setAllowOgden] = useState(true);
	// const [allowDowntown, setAllowDowntown] = useState(true);
	const notificationAlertRef = React.useRef(null);
	// const [skuSync, setskuSync] = useState([]);

	const notify = (place, type, msg) => {
		var type = type
		var options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>{msg}</b>
					</div>
				</div>
			),
			type: type,
			icon: "nc-icon nc-bell-55",
			autoDismiss: 7,
		};
		notificationAlertRef.current.notificationAlert(options);
	};

	const [bigcErrorsTable, setBigcErrorsTable] = useState([]);

	useEffect(() => {
		(async () => {
			await axios.get('/api/retail-bigc-errors')
				.then((response) => {
					setBigcErrorsTable(response.data)
				}).catch(err => {
					console.log(('Error here', err.response.data))
				})
		})()
	}, []);

	const [caErrorsTable, setCaErrorsTable] = useState([]);

	useEffect(() => {
		(async () => {
			await axios.get('/api/retail-ca-errors')
				.then((response) => {
					setCaErrorsTable(response.data)
				}).catch(err => {
					console.log(('Error here', err.response.data))
				})
		})()
	}, []);

	console.log('BIGC-Error-Table:', bigcErrorsTable)
	console.log('CA-Error-Table:', caErrorsTable)

	return (
		<>
			<div className="rna-container">
				<NotificationAlert ref={notificationAlertRef} />
			</div>
			<Container fluid className="pl-0 pr-0 ml-0 mr-0">
				<Row>
					<Col md="12" className='align-content-sm-center '>
						<Tab.Container
							id="icons-tabs-example"
							defaultActiveKey="info-icons"
						>
							<Nav justify role="tablist" variant="pills" className="mb-0 p-0 border bg-light">
								<Nav.Item>
									<Nav.Link eventKey="bigcomm-errors">
										BigCommerce Cannot Sync
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="channeladvisor-errors">
										Channel Advisor Cannot Sync
									</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey="bigcomm-errors">
									<Card>
										<Row>
											<Col md="12">
												<Card className="card-plain">
													<Card.Header className='mt-2 mb-1'>
														<h6>BigCommerce Not Found Items</h6>
													</Card.Header>
													<Card.Body>
														<Table className="table-striped">
															<thead>
																<tr className="m-2 p-2">
																	<th className='text-dark' width="50px">ID</th>
																	<th className='text-dark' width="100px">SKU</th>
																	<th className='text-dark' width="350px">TITLE</th>
																	<th className='text-dark'>SYNC-QTY</th>
																	<th className='text-dark'>ERROR</th>
																	<th className='text-dark'>SV WAREHOUSE</th>
																	<th className='text-dark'>BIGC LOCATION</th>
																	<th className='text-dark'>CA DIST CENTER</th>
																	<th className='text-dark'>Created At</th>
																</tr>
															</thead>
															<tbody>
																{bigcErrorsTable.map((item) =>
																	<RetailSyncTableBigC
																		key={item.id}
																		id={item.id}
																		sku={item.sku}
																		title={item.title}
																		quantity={item.quantity}
																		bigc_error={item.bigc_error}
																		svcode={item.svcode}
																		bigcode={item.bigcode}
																		cacode={item.cacode}
																		created_at={item.created_at}
																	/>
																)}
															</tbody>
														</Table>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Card>
								</Tab.Pane>
								<Tab.Pane eventKey="channeladvisor-errors">
									<Card>
										<Row>
											<Col md="12">
												<Card className="card-plain">
													<Card.Header className='mt-2 mb-1'>
														<h6>Channel Advisor  Not Found Items</h6>
													</Card.Header>
													<Card.Body>
														<Table className="table-striped">
															<thead style={{ backgroundColor: '#def2ff' }}>
																<tr className="m-2 p-2">
																	<th width="50px" className='text-dark'>ID</th>
																	<th width="100px" className='text-dark'>SKU</th>
																	<th width="350px" className='text-dark'>TITLE</th>
																	<th className='text-dark'>SYNC-QTY</th>
																	<th className='text-dark'>ERROR</th>
																	<th className='text-dark'>SV WAREHOUSE</th>
																	<th className='text-dark'>BIGC LOCATION</th>
																	<th className='text-dark'>CA DIST CENTER</th>
																	<th className='text-dark'>Created At</th>
																</tr>
															</thead>
															<tbody>
																{caErrorsTable.map((item) =>
																	<RetailSyncTableCa
																		key={item.id}
																		id={item.id}
																		sku={item.sku}
																		title={item.title}
																		quantity={item.quantity}
																		ca_error={item.ca_error}
																		svcode={item.svcode}
																		bigcode={item.bigcode}
																		cacode={item.cacode}
																		created_at={item.created_at}
																	/>
																)}
															</tbody>
														</Table>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									</Card>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</Container>
		</>
	);
}
export default RetailSyncs; 