import React from "react";
import { Link, useLocation } from "react-router-dom";

 
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Col,
} from "react-bootstrap";

function PublicNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const location = useLocation();
  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant={collapseOpen ? "white" : "transparent"}
      >
        <Container>
          <div className="navbar-wrapper">
          <div className="logo-img align-items-center">
                <img
                  className={"l9-logo"}
                  src={require("../../../assets/img/l9-logo-white.png").default}
                  alt="react-logo"
                />
              </div>
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              <span className="d-none d-md-block h3">Consignment</span>
              <span className="d-block d-md-none">Consignment</span>
            </Navbar.Brand>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              <Nav.Item
                className={
                  location.pathname === "/public/register"
                    ? "active mr-1"
                    : "mr-1 pl-2"
                }
              >
                <Nav.Link to="/public/register" as={Link}>
                  <i className="nc-icon nc-notes pl-2"></i>
                  Registration
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={
                  location.pathname === "/public/login"
                    ? "active mr-1"
                    : "mr-1"
                }
              >
                <Nav.Link to="/public/login" as={Link}>
                  <i className="nc-icon nc-settings-gear-64 pl-2"></i>
                  Admin
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={
                  location.pathname === "/public/lock-screen"
                    ? "active mr-1"
                    : "mr-1"
                }
              >
                {/* <Nav.Link to="/public/lock-screen" as={Link}>
                  <i className="nc-icon nc-key-25 pl-2"></i>
                  Lock
                </Nav.Link> */}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default PublicNavbar;
