import React, { useState } from 'react';
const fetch = require("node-fetch");

import {
    Container,
} from "react-bootstrap";

// const Listings = () => {
const UrlUpload = () => {
    //From Will ON
    const [url, setUrl] = useState('');
    const [sku, setSku] = useState(null);
    const [file, setFile] = useState('');
    const [imageNumber, setImageNumber] = useState(null);
    const [removeBackground, setRemoveBackground] = useState(false);
    const [flag, setFlag] = useState(false);
    const [displayImage, setDisplayImage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [caUpload, setCaUpload] = useState(null)
    const [caError, setCaError] = useState(null)

    const handleCheckboxChange = (event) => {
        setRemoveBackground(event.target.checked);
    };

    const deleteImage = async e => {
        setFlag(false);

        e.preventDefault()

        var formData = new FormData();
        formData.append("sku", sku);
        formData.append("imageNumber", imageNumber);

        const headers = {
            method: 'POST',
            body: formData,
            redirect: 'follow',
        }
        fetch('https://l9golf.com/DeleteSingleImage', headers)
            .then(response => response)
            .then(response => {
                console.log(response, 'response.data', ': Response');
                setDisplayImage(null);
                setError("Deleted");
            }).catch(error => console.log('HandleSubmit Error:', error)).finally(() => {
            })


    }



    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true);
        var FormData = require('form-data');
        var data = new FormData();

        data.append("url", url);
        data.append("file", file[0]);
        data.append("sku", sku);
        data.append("imageNumber", imageNumber);
        data.append("removeBackground", removeBackground);
        data.append("flag", flag);

        const headers = {
            method: 'POST',
            body: data,
            redirect: 'follow',
        }

        fetch('https://l9golf.com/UrlUpload', headers)
            // fetch('http://127.0.0.1:5000/UrlUpload', headers)
            .then(response => response.json())
            .then(response => {
                console.log(response, 'response.data', ': Response');
                setDisplayImage(response.displayImage);
                setFlag(false);
                setError(null);
                if (response.error) {
                    setError(response.error);
                    if (response.flag) setFlag(true);
                }
            }).catch(error => console.log('HandleSubmit Error:', error))
            .finally(() => {
                setLoading(false);
            })

    }
    const handleUpload = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            setError(null)

            formData.append("url", displayImage);
            formData.append("file", file[0]);
            formData.append("sku", sku);
            formData.append("imageNumber", imageNumber);
            formData.append("removeBackground", removeBackground);
            formData.append("flag", flag);
            formData.append("clientUrl", "urlUpload");

            fetch("https://l9golf.com/CaUpload", {
                method: "POST",
                body: formData,
                credentials: "include" // equivalent to { withCredentials: true } in axios
            })
                .then(uploadResponse => {
                    if (uploadResponse.ok) {
                        console.log(uploadResponse)
                        return uploadResponse.json(); // Parse the JSON response
                    } else {
                        return uploadResponse.json().then(data => {
                            setError(data.errors);
                            alert("There was an issue uploading the Rithium. Please download the CSV and import it manually. If this issue persists, reach out to the IT department to get it resolved.");
                            throw new Error("Upload failed"); // Throw an error to skip to the catch block
                        });
                    }
                })
                .then(data => {
                    if (data.errors && data.errors.length > 0) {
                        setCaError("There was an issue uploading the following SKU(s): " + data.errors + ". If the SKU is correct try again or copy the url and manually input add it to Rithium.");
                    }
                    else {
                        setCaUpload(true)
                    }
                })
                .catch(error => {
                    // Handle errors here
                    setError(error.message);
                    console.error(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });

        } catch (error) {
            // Handle any errors that might occur outside of fetch
            setError(error.message);
            console.error(error.message);
            setLoading(false);
        }
    };



    return (
        <>
            <Container>
                <div className="container mt-5">
                    <h2>Single Image Upload</h2>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="form-group col">
                                    <label className="form-label">URL:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="url"
                                        onChange={(event) => {
                                            setUrl(event.target.value);
                                            setFile('');
                                        }}
                                        disabled={file.length > 0}
                                    />
                                </div>
                                <div className="col">
                                    <label className="form-label">Or select a file:</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(event) => {
                                            setFile(event.target.files);
                                            setUrl('');
                                        }}
                                        disabled={url !== ''}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">SKU:</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="sku"
                                    onChange={(event) => setSku(event.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Image Number:</label>
                                <input
                                    className="form-control w-25"
                                    type="number"
                                    name="image_number"
                                    min={1}
                                    max={10}
                                    onChange={(event) => setImageNumber(event.target.value)}
                                    required
                                />
                            </div>
                            <div className="row g-3 mt-1">
                                <div className="col-auto">
                                    <label className="form-check-label">
                                        Remove Background?
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={handleCheckboxChange}
                                        defaultChecked={false}
                                    />
                                </div>
                            </div>


                            {/* <div className="form-check">
                                    <label className="form-check-label">Remove Background</label>
                                    <input
                                        // className="form-check-input"
                                        type="checkbox"
                                        name="removeBackground"
                                        value={false}
                                        onChange={handleCheckboxChange}
                                        defaultChecked={false}
                                    />
                                </div> */}

                            {error && (
                                <div className="row">
                                    <div className="">{error}</div>
                                    {flag && <div className="col"><input className="btn btn-danger" type="submit" value="Yes" />
                                    </div>}
                                </div>
                            )}
                            {caError && (
                                <div className="row">
                                    <div className="">{caError}</div>
                                </div>
                            )}
                            <input type="hidden" name="flag" value={flag} />
                            <div className="row g-3 mt-1">
                                <div className="col-auto">
                                    <input
                                        className="btn btn-primary"
                                        type="submit"
                                        value="Submit"
                                    />
                                </div>
                                {loading && (
                                    <div className="col-auto">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden"></span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                    {caUpload && (
                        <div>
                            <p>Image uploaded to Rithum successfully</p>
                        </div>)}
                    {displayImage && (
                        <div>
                            {/* button that allows you to copy the displayImage text to clipboard */}
                            <button
                                className="btn btn-primary mt-1 mb-1"
                                onClick={() => {
                                    navigator.clipboard.writeText(displayImage);
                                }}>
                                Copy Image URL
                            </button>
                            <div>
                                <button
                                    className="btn btn-primary mt-1 mb-1"
                                    onClick={handleUpload}>
                                    Upload to Rithum
                                </button>
                            </div>
                            <h3>Thumbnail</h3>
                            <div>
                                {/* Date.now() is needed so that the page rerenders the new image if the user overwrites the old image */}
                                <img
                                    src={`${displayImage}?${Date.now()}`}
                                    alt="displayImage"
                                    style={{
                                        width: "250px",
                                        height: "250px",
                                        padding: "10px",
                                        margin: "10px",
                                    }}
                                />
                            </div>
                            <h3>Product Page</h3>
                            <div>
                                {/* Date.now() is needed so that the page rerenders the new image if the user overwrites the old image */}
                                <img
                                    src={`${displayImage}?${Date.now()}`}
                                    alt="displayImage"
                                    style={{
                                        width: "700px",
                                        height: "700px",
                                        padding: "10px",
                                        margin: "10px",
                                    }}
                                />
                            </div>
                            <input
                                className="btn btn-danger mb-5"
                                type="button"
                                value={"Delete"}
                                onClick={deleteImage}
                            />
                        </div>
                    )}
                </div >
            </Container >
        </>
    )
    //From Will OFF
}

export default UrlUpload;
