import React from "react";
import { Switch, Route } from "react-router-dom";

import PublicNavbar from "./Components/Navbars/PublicNavbar.js";
import PublicFooter from "./Components/Footers/PublicFooter.js";
import Loading from '../views/Components/Static/Loading/Loader';

import routes from "../routes.js";

function Public() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/public") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper wrapper-full-page">
        <PublicNavbar />
        <Switch>{getRoutes(routes)}</Switch>
        <PublicFooter />
      </div>
      <Loading />
    </>
  );
}

export default Public;
