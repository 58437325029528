import algoliasearch from 'algoliasearch/lite';
import React, { useState } from 'react';
import ItemCard from '../Components/ItemCard';

import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import '../Components/Search/CustomSearch.css';

import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";

//search only API scope creds here
const searchClient = algoliasearch(
  'CXH9158VXD',
  'e9ab11524509192ff753e271974c00e1'
);

function Search() {
  return (
    <div className="ais-InstantSearch">
      <InstantSearch indexName="cnor_item" searchClient={searchClient}>
        <div className="left-panel">
          <ClearRefinements />
          <h4>Location</h4>
          <RefinementList attribute="store" />
          <h4>Category</h4>
          <RefinementList attribute="category"
            searchable={true}
            limit={8}
            showMore
            showMoreLimit={300}
          />
          <Configure hitsPerPage={9} />
          <h4>Brands</h4>
          <RefinementList attribute="brand"
            searchable={true}
            limit={8}
            showMore
            showMoreLimit={300}
          />
          <Configure hitsPerPage={12} />
        </div>

        <div className="right-panel">
          <SearchBox />
          <Hits hitComponent={Hit} />
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  );

}

function Hit(props) {
  const [modalItemStatus, setModalItemStatus] = useState(false);

  return (
    <>
      <Row className='mt-1' style={{ height: 17.5 + 'rem' }}>
        <Col>
          <Row className='pt-1 pb-1' style={{ height: 2.8 + 'rem' }}>
            <Col lg="3" className="text-muted cust-h">Product </Col>
            <Col lg="9">
              <Highlight attribute="name" className="text-info cust-v" hit={props.hit} /> -
              <span className="text-success"> ${props.hit.price}</span>
            </Col>
          </Row>
          <Row className='pt-1 pb-1' style={{ height: 2.8 + 'rem' }}>
            <Col lg="3" className="text-muted cust-h">Category </Col>
            <Col lg="9"><span className="cust-sub-v">{props.hit.category}</span></Col>
          </Row>
          <Row className='pt-1 pb-1'>
            <Col lg="3" className="text-muted cust-h">Brand </Col>
            <Col lg="9"><span className="cust-sub-v text-secondary">{props.hit.brand}</span></Col>
          </Row>
          <Row className='pt-1 pb-1'>
            <Col lg="3" className="text-muted cust-h">Name </Col>
            <Col lg="9"><span className="cust-sub-v text-secondary">{props.hit.firstName} {props.hit.lastName}</span></Col>
          </Row>
          <Row className='pt-1 pb-1' style={{ height: 2.8 + 'rem' }}>
            <Col lg="3" className="text-muted cust-h">Email </Col>
            <Col lg="9"><span className="cust-sub-v text-secondary" style={{lineBreak: 'anywhere'}}>{props.hit.email}</span></Col>
          </Row>
          <Row className='pt-1 mb-0 pb-0'>
            <Col lg="3" className="text-muted cust-h">SKU </Col>
            <Col lg="9"><span className="cust-sub-v text-secondary">{props.hit.customSKU}</span></Col>
          </Row>
          <Row className='mb-0 pb-0'>
            <Col lg="12" className="d-flex justify-content-end mb-0 pb-0">
              <Button
                onClick={() => {
                  setModalItemStatus(!modalItemStatus)
                }}
                variant="info"
                size="med"
                className="text-info btn-link mt-4 mb-0 pb-0"
              >
                <i className="fas fa-tags mr-1" />
                Edit Item
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        dialogClassName="my-modal-select"
        className="modal modal-primary"

        onHide={() => setModalItemStatus(!modalItemStatus)}
        show={modalItemStatus}
      >
        <Modal.Header className="justify-content-center mb-3"></Modal.Header>
        <Modal.Body className="text-center mt-0 mb-0 pb-0 pt-0 mr-0 pr-0 ml-0 pl-0">
          <ItemCard
            itemID={props.hit.objectID}
            firstName={props.hit.firstName}
            lastName={props.hit.lastName}
            dbID={props.hit.dbID}
          />
        </Modal.Body>
        <div className="modal-footer ml-auto">
          <Button
            className="btn-simple"
            onClick={() =>
              setModalItemStatus(!modalItemStatus)
              // handleGetStatus()
            }
            variant="link"
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default Search;
