import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import 'react-phone-number-input/style.css'
import { useSelector } from 'react-redux';

import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
} from "react-bootstrap";

function SkuvaultReturn(props) {
    const notificationAlertRef = React.useRef(null);    
    const notify = (place, type, msg) => {
        var type = type
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 10,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const username = useSelector((reduxState) => reduxState.username);

    // Validators
    const isRequired = (value) => value !== null && value !== "" && value;

    //First name
    const [skuadd, setSkuAdd] = useState('');
    const [fnRequiredState, setAsRequiredState] = useState(true);


    // handlers 
    const resetResponseError = () => (setErrorState(true))

    const handleAddSku = (e) => {
        setSkuAdd(e.target.value);
        isRequired(e.target.value) ? setAsRequiredState(true) : setAsRequiredState(false);
    }

    //Check required and if all true call the API
    const handleSubmit = e => {
        e.preventDefault();
        let formNotFilled = 0
        if (!fnRequiredState || !isRequired(skuadd)) {
            setAsRequiredState(false);
            formNotFilled++
        }

        if (formNotFilled > 0) {
            return
        }
        else {
            setAsRequiredState(true);
        }
        props.showLoader()
        axios.post('/api/add', { skuadd, username })
            .then((response) => {
                props.hideLoader()
                if(response.data[0].AddItemStatus === 'Success'){
                    notify("bc", "success", `${response.data[0].AddItemStatus} - ${response.data[1]} Was added to SkuVault Inventory!`)
                }
                if(response.data[0].AddItemStatus === 'SkuNotFound'){
                    notify("tc", "danger", `${response.data[0].AddItemStatus} - ${response.data[1]} This SKU was not found in SkuVault!`)
                }
                setSkuAdd('')
            }).catch((err) => {
                props.hideLoader()
                notify("tc", "danger", `${err.response.data} - There has been an error, please nofitfy IT with this error message!`)
                // setErrorResponse(err.response.data)
            })
    }
    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>            
            <Form action="#" method="#">
                <Card className="card-plain">
                    <div className="card-body">
                        <Form.Group
                            className={
                                fnRequiredState ? "has-success" : "has-error"
                            }
                        >
                            <Form.Control
                                placeholder="Scan SKU to return SkuVault inventory..."
                                type="text"
                                value={skuadd}
                                onChange={handleAddSku}
                            ></Form.Control>
                            {fnRequiredState ? null : (
                                <label className="error">
                                    SKU is required.
                                </label>
                            )}
                        </Form.Group>
                    </div>
                    <div className="card-footer text-center">
                        <Button
                            className="btn-fill btn-success btn-wd text-light"
                            type="submit"
                            variant="default"
                            onClick={handleSubmit}
                        >
                            Return Item Inventory
                        </Button>
                    </div>
                </Card>
            </Form>
        </>
    );
}

const mapDispatchToProps = {
    showLoader,
    hideLoader
}

export default connect(undefined, mapDispatchToProps)(SkuvaultReturn);