import React, { useState, useEffect } from 'react';
var Barcode = require('react-barcode');
import './Printrpg.css'
import LeftLogo from './L9_logo_left.png'
import LeftRight from './L9_logo_right.png'

import {
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
} from "react-bootstrap";

function Prinrgp(props) {
    useEffect(() => {
        setPrintIDParam(props.match.params.id)
        setPrintPrice(props.match.params.price)
        setPrintTitle(props.match.params.title)
        setprintCreatedBy(props.match.params.createdBy)
        setDate(props.match.params.date)
    }, []);

    const [printIDParam, setPrintIDParam] = useState('');
    const [printPrice, setPrintPrice] = useState('');
    const [printTitle, setPrintTitle] = useState('');
    const [printCreatedBy, setprintCreatedBy] = useState('');
    const [date, setDate] = useState('');

    var formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        currency: 'USD',
    });

    let formatted = formatter.format(printPrice);
    let formatStyle = formatted.toString().split('.')
    let price = formatStyle[0]
    let cents = formatStyle[1]

    return (
        <>
            <Media>
                <Media.Body>
                    <div className="print-container">
                        <div className="core-container">
                            <div className="logobg"><img className="logoprint" src={LeftLogo} alt="Logo" /></div>
                            <div>
                                <div>
                                    <div className="print-location">
                                        <div className="print-location-left">{printCreatedBy} - RGP Item No Return</div>
                                        <div className="print-location-right">No Return - Added on: {date}</div>
                                    </div>
                                    <div className="title-container">
                                        <div className="label-left">
                                            <div className="label-left-inner">
                                                <div className="price-container">
                                                    <span className="currency-symbol">$</span>
                                                    <span className="price">{price}</span>
                                                    <span className="cents">.{cents}</span>
                                                </div>
                                                <div className="title">
                                                    <span>{printTitle}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="label-right">
                                            <Barcode
                                                value={printIDParam}
                                                format={'CODE128'}
                                                height={42}
                                                fontSize={16}
                                                textMargin={5}
                                                width={1.3}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div><img className="logoprint" src={LeftRight} alt="Logo" /></div>
                        </div>
                    </div>
                </Media.Body>
            </Media>
        </>
    );
}
// window.onload = function() { window.print(), setTimeout(window.close, 0);}
export default Prinrgp;
// save this function for a bit until verified package checkdigit works correctly
// function checkDigit(val) {
//     let array = val.split('').reverse();

//     let total = 0;
//     let i = 1;
//     array.forEach(number => {
//         number = parseInt(number);
//         if (i % 2 === 0) {
//             total = total + number;
//         }
//         else {
//             total = total + (number * 3);
//         }
//         i++;
//     });

//     return (Math.ceil(total / 10) * 10) - total;
// }
// let digit = checkDigit(printIDParam)
// let UPC = printIDParam + digit