import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UsersList from './UsersList';

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table,
} from "react-bootstrap";

const CreateUser = (props) => {


    let [userList, setuserList] = useState([]);

    useEffect(() => {
        axios.get('/auth/user').then((response) => {
            const { isAdmin } = response.data
            if (!isAdmin) props.history.push('/public/login');
        })
        axios.get(`/auth/users`)
            .then((response) => {
                setuserList(response.data)
            }).catch(err => {
                console.log((err))
            })
    }, []);

    const userReload = () => {
        axios.get(`/auth/users`)
            .then((response) => {
                setuserList(response.data)
            }).catch(err => {
                console.log((err))
            })
    }
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [svPassword, setsvPassword] = useState('');
    const [isAdmin, setisAdmin] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleUsernameChange = e => setUsername(e.target.value);
    const handlePasswordChange = e => setPassword(e.target.value);
    const handlesvPasswordChange = e => setsvPassword(e.target.value);
    const handleConfirmPasswordChange = e => setConfirmPassword(e.target.value);

    const toggleAdmin = React.useCallback(() => {
        setisAdmin(v => !v);
    }, []);


    const handleSubmit = e => {
        e.preventDefault();
        if (password === confirmPassword) {
            console.log('SVPASS',svPassword)
            axios
                .post('/auth/register', { isAdmin, username, password})
                .then((response) => {
                    console.log(response);
                    // props.history.push('/new');
                }).catch(err => console.log(err));
        } else {
            setError('One of your passwords does not match');
        }
    }



    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="stacked-form">
                            <Card.Header>
                                <Card.Title as="h4">Create User</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form action="/" className="form-horizontal" method="get">
                                    <Form.Group>
                                        <label>Username</label>
                                        <Form.Control
                                            onChange={handleUsernameChange}
                                            placeholder="Enter Username"
                                            type="email"
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Password</label>
                                        <Form.Control
                                            onChange={handlePasswordChange}
                                            placeholder="Password"
                                            type="password"
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Confirm Password</label>
                                        <Form.Control
                                            onChange={handleConfirmPasswordChange}
                                            placeholder="Password"
                                            type="password"
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check>
                                            <Form.Check.Label>
                                                <Form.Check.Input
                                                    onChange={() => toggleAdmin()}
                                                    type="checkbox"
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                                Is Admin User
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>SkuVault Password</label>
                                        <Form.Control
                                            onChange={handlesvPasswordChange}
                                            placeholder="Password"
                                            type="password"
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group></Form.Group>                                    
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                {error}
                                <Button className="btn-fill" type="submit" variant="info" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card className="stacked-form">
                            <Card.Header>
                                <Card.Title as="h4">Users</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="text-left">ID</th>
                                            <th className="text-left">User</th>
                                            <th className="text-left">Admin</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>

                                    {userList.map((user, i) =>
                                        <UsersList
                                            key={i}
                                            id={user.id}
                                            username={user.username}
                                            is_admin={user.is_admin}
                                            userReload={userReload}
                                        />
                                    )}

                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default CreateUser
