import React, { useState, useEffect } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";


function EditConsignor({ cnor, firstName, lastName, email, phone, address, city, state, zip, cnorNotes,
    handleFirstName, handleLastName, handleEmail, handlePhone, handleAddress, setModal, fnReqSearchQueryState, defaultTableUpdated, search, handleCnortNotes,
    handleCity, handleState, handleZip, handleSubmit, handleAllowText, phoneAllowText}) {
console.log('txt',phoneAllowText)
    const [alert, setAlert] = useState(null);

    const deleteConsignor = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => successDelete()}
                onCancel={() => cancelDetele()}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="info"
                confirmBtnText="Yes, delete Consignor!"
                cancelBtnText="Cancel"
                showCancel
            >
                You will not be able to recover Consignor data
            </SweetAlert>
        );
    };
    //Delete consignor 
    const successDelete = () => {
        axios.delete(`/cnor/delete/${cnor}`)
            .then((response) => {
                setModal()
                if(fnReqSearchQueryState === true){
                    defaultTableUpdated();
                } else {
                    search();
                }
            }).catch(err => {console.log(err)})
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Deleted!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                This Consignor Has Been Deleted
            </SweetAlert>
        );
    };
    const cancelDetele = () => {
        setAlert(
            <SweetAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Cancelled"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                No problem, we all press buttons by mistake!
            </SweetAlert>
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };

    return (
        <>
            {alert}
            <Container fluid>
                <div className="section-image" >
                    <Container>
                        <Row>
                            <Col md="12" sm="6">
                                <Form action="" className="form" method="">
                                    <Card>
                                        <Card.Header>
                                            <Card.Header>
                                                <Card.Title as="h4">Consignor Profile</Card.Title>
                                            </Card.Header>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col className="pr-1" md="2">
                                                    <Form.Group>
                                                        <label>Consigner ID</label>
                                                        <Form.Control
                                                            defaultValue={cnor}
                                                            disabled
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pl-1" md="4">
                                                    <Form.Group>
                                                        <label>
                                                            Email address
                                                        </label>
                                                        <Form.Control
                                                            onChange={handleEmail}
                                                            defaultValue={email}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group>
                                                        <label>
                                                            Phone
                                                        </label>
                                                        <Form.Control
                                                            onChange={handlePhone}
                                                            defaultValue={phone}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <Form.Group>
                                                        <label>First Name</label>
                                                        <Form.Control
                                                            onChange={handleFirstName}
                                                            defaultValue={firstName}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group>
                                                        <label>Last Name</label>
                                                        <Form.Control
                                                            onChange={handleLastName}
                                                            defaultValue={lastName}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Form.Group>
                                                        <label>Address</label>
                                                        <Form.Control
                                                            onChange={handleAddress}
                                                            defaultValue={address}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="3">
                                                    <Form.Group>
                                                        <label>City</label>
                                                        <Form.Control
                                                            onChange={handleCity}
                                                            defaultValue={city}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="px-1" md="3">
                                                    <Form.Group>
                                                        <label>State</label>
                                                        <Form.Control
                                                            onChange={handleState}
                                                            defaultValue={state}
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pl-1" md="3">
                                                    <Form.Group>
                                                        <label>Postal Code</label>
                                                        <Form.Control
                                                            onChange={handleZip}
                                                            defaultValue={zip}
                                                            type="number"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col className="pl-1" md="3">
                                                    <Form.Group>
                                                        <label>Text Communication:</label>
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch-11"
                                                            className="mb-1 mr-2 pr-5 pl-1"
                                                            onChange={e => handleAllowText(e)}
                                                            checked={phoneAllowText}
                                                        />                                                        
                                                    </Form.Group>
                                                </Col>                                                 */}
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Form.Group>
                                                        <label>Notes</label>
                                                        <Form.Control
                                                            cols="80"
                                                            onChange={handleCnortNotes}
                                                            defaultValue={cnorNotes}
                                                            rows="4"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button
                                                className="btn-fill pull-right"
                                                type="submit"
                                                variant="info"
                                                onClick={handleSubmit}
                                            >
                                                Update Consignor
                                            </Button>
                                            <Button
                                                className="btn-fill pull-right ml-4"
                                                variant="danger"
                                                onClick={deleteConsignor}
                                            >
                                                Delete Consignor
                                            </Button>
                                            <div className="clearfix"></div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </>
    );
}

export default EditConsignor;
