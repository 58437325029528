import React from "react";
import ConsignorSignup from "../Components/ConsignorSignup";
import RegisterCTA from "../Components/Static/RegisterCTA";

import {
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  FormGroup,
  FormControl,
} from "react-bootstrap";


function Register() {  
  //Random background color to mix it up
  //Removed other colors from array, remove radom down the road-NM jun27 2023
  var colorsCycle=['blue','black','purple'];
  var random = Math.floor(Math.random() * colorsCycle.length);
  var colorMe = colorsCycle[random];
  return (
    <>
      <div
        className="full-page register-page section-image"
        data-color={colorMe}
        data-image={require("../../assets/img/bg7.jpg").default}
      >
        <div className="content d-flex align-items-top">
          <Container>
            <Card className="card-register card-plain text-center">
              <Card.Header>
                <Row className="justify-content-center">
                  <Col md="10" lg="12">
                    <div className="header-text">
                      <Card.Title as="h2">
                        Sign up to sell your gear!
                      </Card.Title>
                      <hr></hr>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="ml-auto" md="7" lg="6">
                    <RegisterCTA />
                  </Col>
                  <Col className="ml-auto mr-auto" md="5" lg="6">
                  <Media>
                        <div className="media-left">
                            <div className="icon">
                                <i className="fas fa-user-circle mr-3"></i>
                            </div>
                        </div>
                        <Media.Body>
                            <h4>Consignment Sign up!</h4>
                            <p>
                                Please enter all information below. ( <span className="error-agree">All fields are requred</span> )
                            </p>
                        </Media.Body>
                    </Media>
                    <ConsignorSignup />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/full-screen-image-5.jpg").default + ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default Register;
